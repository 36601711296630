import { Application } from '../models';
import { Party, Flattened as FlattenedParty } from '../party';

export type Participant<P extends Party | FlattenedParty = Party> = {
  id: string;
  partyId: string;
  party?: P;
  applicationId: string;
  application?: Application;

  /**
   * @deprecated To be removed in DEV-12304
   */
  roleId: string;
  /**
   * @deprecated To be removed in DEV-12304
   */
  role?: ParticipantRole;
  roles: ParticipantRole[];

  createdAt?: Date;
  updatedAt?: Date;
};

export type ParticipantRoleType = {
  identifier: string;
  id: string;
  name: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  roles?: ParticipantRole[];
};

export type ParticipantRole = {
  id: string;
  type: string;
  position: number;
  name?: string;
  description?: string;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export type ParticipantParticipantRole = {
  id: string;
  participantId: string;
  roleId: string;
};

export enum ParticipantRoles {
  INSURED = 'INSURED',
  OWNER_PERSON = 'OWNER_PERSON',
  LEGAL_GUARDIAN = 'LEGAL_GUARDIAN',
  PAYER = 'PAYER',
  SECONDARY_PAYER = 'SECONDARY_PAYER',
  OWNER_ORGANIZATION = 'OWNER_ORGANIZATION',
  PRIMARY_BENEFICIARY = 'PRIMARY_BENEFICIARY',
  CONTINGENT_BENEFICIARY = 'CONTINGENT_BENEFICIARY',
  AGENT = 'AGENT',
  TRANSLATOR = 'TRANSLATOR',
  SIGNING_AUTHORITY = 'SIGNING_AUTHORITY',
  TRUSTEE = 'TRUSTEE',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  OFFICER = 'OFFICER',
  ANNUITANT = 'ANNUITANT',
  CONTRIBUTOR = 'CONTRIBUTOR',
  SECONDARY_CONTRIBUTOR = 'SECONDARY_CONTRIBUTOR',
}
