import { SectionGroupKey } from './questionnaireBlueprints';

type BasePartIdentifier = { sectionGroupPartName: SectionGroupKey };

export enum PartIdentifierTag {
  sectionGroup = 'sectionGroup',
  section = 'section',
  subsection = 'subsection',
  question = 'question',
  field = 'field',
  selectOption = 'selectOption',
}

export type SectionGroupPartIdentifier = BasePartIdentifier & {
  tag: PartIdentifierTag.sectionGroup;
};

export type SectionPartIdentifier = BasePartIdentifier & {
  tag: PartIdentifierTag.section;
  sectionPartName: string;
};

export type SubsectionPartIdentifier = BasePartIdentifier & {
  tag: PartIdentifierTag.subsection;
  sectionPartName: string;
  subsectionPartName: string;
};

export type QuestionPartIdentifier = BasePartIdentifier & {
  tag: PartIdentifierTag.question;
  sectionPartName: string;
  subsectionPartName: string;
  questionPartName: string;
};

export type FieldPartIdentifier = BasePartIdentifier & {
  tag: PartIdentifierTag.field;
  sectionPartName: string;
  subsectionPartName: string;
  questionPartName: string;
  fieldPartName: string;
};

export type SelectOptionPartIdentifier = BasePartIdentifier & {
  tag: PartIdentifierTag.selectOption;
  sectionPartName: string;
  subsectionPartName: string;
  questionPartName: string;
  fieldPartName: string;
  selectOptionPartName: string;
};

export type PartIdentifier =
  | SectionGroupPartIdentifier
  | SectionPartIdentifier
  | SubsectionPartIdentifier
  | QuestionPartIdentifier
  | FieldPartIdentifier
  | SelectOptionPartIdentifier;

export function isSectionGroupPartIdentifier(
  partIdentifier: PartIdentifier | null,
): partIdentifier is SectionGroupPartIdentifier {
  return !!partIdentifier && partIdentifier.tag === 'sectionGroup';
}

export function isSectionPartIdentifier(
  partIdentifier: PartIdentifier | null,
): partIdentifier is SectionPartIdentifier {
  return !!partIdentifier && partIdentifier.tag === 'section';
}

export function isSubsectionPartIdentifier(
  partIdentifier: PartIdentifier | null,
): partIdentifier is SubsectionPartIdentifier {
  return !!partIdentifier && partIdentifier.tag === 'subsection';
}

export function isQuestionPartIdentifier(
  partIdentifier: PartIdentifier | null,
): partIdentifier is QuestionPartIdentifier {
  return !!partIdentifier && partIdentifier.tag === 'question';
}

export function isFieldPartIdentifier(partIdentifier: PartIdentifier | null): partIdentifier is FieldPartIdentifier {
  return !!partIdentifier && partIdentifier.tag === 'field';
}

export function isSelectOptionPartIdentifier(
  partIdentifier: PartIdentifier | null,
): partIdentifier is SelectOptionPartIdentifier {
  return !!partIdentifier && partIdentifier.tag === 'selectOption';
}
