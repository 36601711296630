import { z } from 'zod';

export enum AvailableUtilities {
  zod = 'zod',
}

export const availableUtilitiesSchema = z.nativeEnum(AvailableUtilities);

export const findUtilitiesTypeDeclarationsParametersSchema = z.object({
  utilities: z.union([
    availableUtilitiesSchema,
    z.array(availableUtilitiesSchema).refine((items) => new Set(items).size === items.length, {
      message: 'Must be an array of unique utilities',
    }),
  ]),
});

export type FindUtilitiesTypeDeclarationsParameters = z.infer<typeof findUtilitiesTypeDeclarationsParametersSchema>;
