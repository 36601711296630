export const TimeMeasurementUnits = {
  minute: 'minute',
  second: 'second',
  millisecond: 'millisecond',
} as const;

export type TimeMeasurementUnit = keyof typeof TimeMeasurementUnits;

export type TimeMeasurement = {
  tag: 'TimeMeasurement';
  name: string;
  value: number;
  unit: TimeMeasurementUnit;
};

export type Metric = TimeMeasurement;
