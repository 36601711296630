import * as z from 'zod';
import { lineOfBusinessSchema } from '../linesOfBusiness';
import { Localizable, localizableSchema } from '../localization';
import { KeepTypeAlias } from '../utilities';

export const questionnaireSchema = z.object({
  id: z.string(),
  carrierId: z.string(),
  name: localizableSchema,
  linesOfBusiness: z.array(lineOfBusinessSchema.pick({ id: true, name: true, tag: true })),
  createdAt: z.date(),
  updatedAt: z.date(),
  archived: z.boolean().optional().nullable(),
});

export const questionnaireWithoutLoBSchema = z.object({
  id: z.string(),
  carrierId: z.string(),
  name: localizableSchema,
  createdAt: z.date(),
  updatedAt: z.date(),
  archived: z.boolean().optional().nullable(),
});

export const questionnaireArraySchema = z.array(questionnaireSchema);

export type Questionnaire = KeepTypeAlias<z.infer<typeof questionnaireSchema>>;

export type QuestionnaireWithoutLoB = KeepTypeAlias<z.infer<typeof questionnaireWithoutLoBSchema>>;

export type CreateQuestionnaireData = { name: Localizable; lineOfBusinessId: string };

export type FindQuestionnairesForProUserQuery = {
  questionnaireVersionId?: string;
};
