import z from 'zod';

import { HoldingForm, ProductType } from '../products';
import { LineOfBusinessName } from '../linesOfBusiness';
import { KeepTypeAlias } from '../utilities';
import {
  CanadianJurisdiction,
  CarrierAppointmentType,
  CarrierForm,
  Country,
  CurrencyType,
  Gender,
  HoldingStatus,
  InquiryReason,
  USAJurisdiction,
} from './enums';

export * from './enums';

const MIBTrackingIdSchema = z.string().refine(
  (value): boolean => !z.string().uuid().safeParse(value).success,
  (value) => ({
    message: `TrackingId ${value} cannot be a uuid. Use reference number of application.`,
  }),
);

export enum Outcome {
  noMatch = 'noMatch',
  HIT = 'HIT',
  TRY = 'TRY',
}

export enum Status {
  success = 'success',
  error = 'error',
  noResponse = 'noResponse',
}

export type CustomMIBObject = {
  outcome: Outcome;
  status: Status;
  rawResponse: string | undefined;
};

const CanadianJurisdictionSchema = z.nativeEnum(CanadianJurisdiction);
const USAJurisdictionSchema = z.nativeEnum(USAJurisdiction);
const JurisdictionSchema = z.union([CanadianJurisdictionSchema, USAJurisdictionSchema]);
const CountrySchema = z.nativeEnum(Country);
const GenderSchema = z.nativeEnum(Gender);
const MIBInquiryReasonSchema = z.nativeEnum(InquiryReason);
const CarrierFormSchema = z.nativeEnum(CarrierForm);
const CarrierAppointmentTypeSchema = z.nativeEnum(CarrierAppointmentType);
const HoldingStatusSchema = z.nativeEnum(HoldingStatus);
const CurrencyTypeSchema = z.nativeEnum(CurrencyType);
const HoldingFormSchema = z.nativeEnum(HoldingForm);
const LineOfBusinessSchema = z.nativeEnum(LineOfBusinessName);
const ProductTypeSchema = z.nativeEnum(ProductType);

export const mibInquirySchema = z.object({
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
  birthDate: z.string(),
  birthJurisdiction: JurisdictionSchema.nullable(),
  birthCountry: CountrySchema.nullable(),
  gender: GenderSchema,
  governmentId: z.string().nullable(),
  governmentIdTypeCode: z.string().nullable(),
  residenceJurisdiction: JurisdictionSchema,
  residencePostalCode: z.string(),
  residenceCountry: CountrySchema,
  occupation: z.string().nullable(),
  mibInquiryReason: MIBInquiryReasonSchema,
  holdingForm: HoldingFormSchema,
  lineOfBusinessType: LineOfBusinessSchema,
  carrierForm: CarrierFormSchema,
  carrierAppointmentTypeCode: CarrierAppointmentTypeSchema,
  productType: ProductTypeSchema,
  holdingStatus: HoldingStatusSchema,
  policyFaceAmount: z.number(),
  currencyTypeCode: CurrencyTypeSchema,
  trackingId: MIBTrackingIdSchema, // Use reference number of application - this ID cannot be a UUID (MIB will reject it)
  transactionReferenceId: z.string(), // This is the ID returned with the outcome
});

export type Inquiry = KeepTypeAlias<z.infer<typeof mibInquirySchema>>;
