export enum RuleReasonValue {
  pass = 'pass',
  fail = 'fail',
}
export type RulesOutcomeConsideration = Record<string, RuleReasonValue>;

export type JetDecisionOutcomes = {
  participantId: string;
  participant: Record<string, string>;
  rulesOutcomeList: RulesOutcomeConsideration;
};
