import * as t from 'typegate';

// NOTE: Please update the README.md in shared/questionnaire-engine if this is modified.
export const isoCountryCode = t.union(
  t.literal('AF'),
  t.literal('AX'),
  t.literal('AL'),
  t.literal('DZ'),
  t.literal('AS'),
  t.literal('AD'),
  t.literal('AO'),
  t.literal('AI'),
  t.literal('AQ'),
  t.literal('AG'),
  t.literal('AR'),
  t.literal('AM'),
  t.literal('AW'),
  t.literal('AU'),
  t.literal('AT'),
  t.literal('AZ'),
  t.literal('BS'),
  t.literal('BH'),
  t.literal('BD'),
  t.literal('BB'),
  t.literal('BY'),
  t.literal('BE'),
  t.literal('BZ'),
  t.literal('BJ'),
  t.literal('BM'),
  t.literal('BT'),
  t.literal('BO'),
  t.literal('BQ'),
  t.literal('BA'),
  t.literal('BW'),
  t.literal('BR'),
  t.literal('IO'),
  t.literal('BN'),
  t.literal('BG'),
  t.literal('BF'),
  t.literal('BI'),
  t.literal('KH'),
  t.literal('CM'),
  t.literal('CA'),
  t.literal('CV'),
  t.literal('KY'),
  t.literal('CF'),
  t.literal('TD'),
  t.literal('CL'),
  t.literal('CN'),
  t.literal('CX'),
  t.literal('CC'),
  t.literal('CO'),
  t.literal('KM'),
  t.literal('CG'),
  t.literal('CD'),
  t.literal('CK'),
  t.literal('CR'),
  t.literal('CI'),
  t.literal('HR'),
  t.literal('CU'),
  t.literal('CW'),
  t.literal('CY'),
  t.literal('CZ'),
  t.literal('DK'),
  t.literal('DJ'),
  t.literal('DM'),
  t.literal('DO'),
  t.literal('EC'),
  t.literal('EG'),
  t.literal('SV'),
  t.literal('GQ'),
  t.literal('ER'),
  t.literal('EE'),
  t.literal('ET'),
  t.literal('FK'),
  t.literal('FO'),
  t.literal('FJ'),
  t.literal('FI'),
  t.literal('FR'),
  t.literal('GF'),
  t.literal('PF'),
  t.literal('TF'),
  t.literal('GA'),
  t.literal('GM'),
  t.literal('GE'),
  t.literal('DE'),
  t.literal('GH'),
  t.literal('GI'),
  t.literal('GR'),
  t.literal('GL'),
  t.literal('GD'),
  t.literal('GP'),
  t.literal('GU'),
  t.literal('GT'),
  t.literal('GG'),
  t.literal('GN'),
  t.literal('GW'),
  t.literal('GY'),
  t.literal('HT'),
  t.literal('VA'),
  t.literal('HN'),
  t.literal('HK'),
  t.literal('HU'),
  t.literal('IS'),
  t.literal('IN'),
  t.literal('ID'),
  t.literal('IR'),
  t.literal('IQ'),
  t.literal('IE'),
  t.literal('IM'),
  t.literal('IL'),
  t.literal('IT'),
  t.literal('JM'),
  t.literal('JP'),
  t.literal('JE'),
  t.literal('JO'),
  t.literal('KZ'),
  t.literal('KE'),
  t.literal('KI'),
  t.literal('KP'),
  t.literal('KR'),
  t.literal('KW'),
  t.literal('KG'),
  t.literal('LA'),
  t.literal('LV'),
  t.literal('LB'),
  t.literal('LS'),
  t.literal('LR'),
  t.literal('LY'),
  t.literal('LI'),
  t.literal('LT'),
  t.literal('LU'),
  t.literal('MO'),
  t.literal('MK'),
  t.literal('MG'),
  t.literal('MW'),
  t.literal('MY'),
  t.literal('MV'),
  t.literal('ML'),
  t.literal('MT'),
  t.literal('MH'),
  t.literal('MQ'),
  t.literal('MR'),
  t.literal('MU'),
  t.literal('YT'),
  t.literal('MX'),
  t.literal('FM'),
  t.literal('MD'),
  t.literal('MC'),
  t.literal('MN'),
  t.literal('ME'),
  t.literal('MS'),
  t.literal('MA'),
  t.literal('MZ'),
  t.literal('MM'),
  t.literal('NA'),
  t.literal('NR'),
  t.literal('NP'),
  t.literal('NL'),
  t.literal('NC'),
  t.literal('NZ'),
  t.literal('NI'),
  t.literal('NE'),
  t.literal('NG'),
  t.literal('NU'),
  t.literal('NF'),
  t.literal('MP'),
  t.literal('NO'),
  t.literal('OM'),
  t.literal('PK'),
  t.literal('PW'),
  t.literal('PS'),
  t.literal('PA'),
  t.literal('PG'),
  t.literal('PY'),
  t.literal('PE'),
  t.literal('PH'),
  t.literal('PN'),
  t.literal('PL'),
  t.literal('PT'),
  t.literal('PR'),
  t.literal('QA'),
  t.literal('RE'),
  t.literal('RO'),
  t.literal('RU'),
  t.literal('RW'),
  t.literal('BL'),
  t.literal('SH'),
  t.literal('KN'),
  t.literal('LC'),
  t.literal('MF'),
  t.literal('PM'),
  t.literal('VC'),
  t.literal('WS'),
  t.literal('SM'),
  t.literal('ST'),
  t.literal('SA'),
  t.literal('SN'),
  t.literal('RS'),
  t.literal('SC'),
  t.literal('SL'),
  t.literal('SG'),
  t.literal('SX'),
  t.literal('SK'),
  t.literal('SI'),
  t.literal('SB'),
  t.literal('SO'),
  t.literal('ZA'),
  t.literal('GS'),
  t.literal('SS'),
  t.literal('ES'),
  t.literal('LK'),
  t.literal('SD'),
  t.literal('SR'),
  t.literal('SJ'),
  t.literal('SZ'),
  t.literal('SE'),
  t.literal('CH'),
  t.literal('SY'),
  t.literal('TW'),
  t.literal('TJ'),
  t.literal('TZ'),
  t.literal('TH'),
  t.literal('TL'),
  t.literal('TG'),
  t.literal('TK'),
  t.literal('TO'),
  t.literal('TT'),
  t.literal('TN'),
  t.literal('TR'),
  t.literal('TM'),
  t.literal('TC'),
  t.literal('TV'),
  t.literal('UG'),
  t.literal('UA'),
  t.literal('AE'),
  t.literal('GB'),
  t.literal('US'),
  t.literal('UM'),
  t.literal('UY'),
  t.literal('UZ'),
  t.literal('VU'),
  t.literal('VE'),
  t.literal('VN'),
  t.literal('VG'),
  t.literal('VI'),
  t.literal('WF'),
  t.literal('EH'),
  t.literal('YE'),
  t.literal('ZM'),
  t.literal('ZW'),
);

export type IsoCountryCode = t.Infer<typeof isoCountryCode>;
