import { getParser } from '@breathelife/result';
import * as z from 'zod';

export enum RiskClassType {
  juvenile = 'juvenile',
  superPreferredNonSmoker = 'superPreferredNonSmoker',
  preferredNonSmoker = 'preferredNonSmoker',
  standardNonSmoker = 'standardNonSmoker',
  preferredSmoker = 'preferredSmoker',
  standardSmoker = 'standardSmoker',
  simplifiedIssueNonSmoker = 'simplifiedIssueNonSmoker',
  simplifiedIssueSmoker = 'simplifiedIssueSmoker',
}

export enum ModalType {
  annual = 'annual',
  semiAnnual = 'semiAnnual',
  quarterly = 'quarterly',
  monthly = 'monthly',
  singlePremium = 'singlePremium',
}

export enum DividendsType {
  paidUpAdditions = 'paidUpAdditions',
  paidInCash = 'paidInCash',
  dividendAccumulation = 'dividendAccumulation',
  premiumReduction = 'premiumReduction',
  loadReduction = 'loadReduction', // i didnt find load reduction in the acord help file there is a loan Reduction though , should i change this ?
}

export const dividendsType = getParser(z.nativeEnum(DividendsType));

export enum TableRatingType {
  unknown = 'unknown',
  t1 = 't1',
  t2 = 't2',
  t3 = 't3',
  t4 = 't4',
  t5 = 't5',
  t6 = 't6',
  t7 = 't7',
  t8 = 't8',
  t9 = 't9',
  t10 = 't10',
  t11 = 't11',
  t12 = 't12',
  t13 = 't13',
  t14 = 't14',
  t15 = 't15',
  t16 = 't16',
}

export enum TaxStatusType {
  nonQualified = 'nonQualified',
  qualified = 'qualified',
}

export enum GenderType {
  male = 'male',
  female = 'female',
  unisex = 'unisex',
  unknown = 'unknown',
  other = 'other',
}
