import { QuestionnaireBlueprint } from '../questionnaireBlueprints/questionnaireBlueprints';

export enum ImportCopyDeckServiceErrors {
  missingQuestionnaireVersionId = 'Missing query parameters to import copy deck',
  missingCopyDeckCSV = 'Must pass copyDeckCSV in request data to import a copy deck.',
  parentIdentifierIsNotSectionPartIdentifier = 'Must pass a sectionPartIdentifier as parentPartIdentifier',
  errorCreatingQuestionnaireModifications = 'Error creating questionnaire modifications',
  errorPatchingQuestionnaire = 'Error patching questionnaire',
}

export enum ImportCopyDeckUnhandledRowErrorType {
  missingAnswerNodeId = 'missingAnswerNodeId',
  missingFieldType = 'missingFieldType',
  fieldTypeNotSupported = 'fieldTypeNotSupported',
  missingGroupingLevel = 'missingGroupingLevel',
  invalidGroupingLevel = 'invalidGroupingLevel',
}

export type ImportCopyDeckUnhandledRowError = {
  type: ImportCopyDeckUnhandledRowErrorType;
  message?: string;
};

export enum CopyDeckColumnTitles {
  tempId = 'tempId',
  fieldName = 'fieldName',
  permanentId = 'permanentId',
  fieldType = 'fieldType',
  groupingLevel = 'groupingLevel',
  repeatable = 'repeatable',
  hidden = 'hidden',
  optional = 'optional',
  disabled = 'disabled',
  lineBreak = 'lineBreak',
  titleEn = 'titleEn',
  titleFr = 'titleFr',
  descriptionEn = 'descriptionEn',
  descriptionFr = 'descriptionFr',
  fieldSize = 'fieldSize',
  fieldPlaceholderEn = 'fieldPlaceholderEn',
  fieldPlaceholderFr = 'fieldPlaceholderFr',
  optionsEn = 'optionsEn',
  optionsFr = 'optionsFr',
  optionsSize = 'optionsSize',
}

export type CopyDeckRowValue = Record<CopyDeckColumnTitles, string>;

export type CopyDeckUnhandledRow = {
  value: CopyDeckRowValue;
  errors: ImportCopyDeckUnhandledRowError[];
};

export type ImportCopyDeckResponse = {
  blueprint?: QuestionnaireBlueprint;
  hasChanged: boolean;
  unhandledRows?: CopyDeckUnhandledRow[];
};
