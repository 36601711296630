import * as z from 'zod';
import { KeepTypeAlias } from '../utilities';

const adhocRequestDownloadFailureEvent = z.object({
  eSignAdhocRequestId: z.string(),
  packageId: z.string(),
  type: z.literal('AdhocRequestDownloadFailureEvent'),
});

export type AdhocRequestDownloadFailureEvent = KeepTypeAlias<z.infer<typeof adhocRequestDownloadFailureEvent>>;

const adhocRequestOnCompleteFailureEvent = z.object({
  eSignAdhocRequestId: z.string(),
  type: z.literal('AdhocRequestOnCompleteFailureEvent'),
});

export type AdhocRequestOnCompleteFailureEvent = KeepTypeAlias<z.infer<typeof adhocRequestOnCompleteFailureEvent>>;

const backendEventsPayload = z.union([adhocRequestDownloadFailureEvent, adhocRequestOnCompleteFailureEvent]);

export type BackendEventsPayload = KeepTypeAlias<z.infer<typeof backendEventsPayload>>;
