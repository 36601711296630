import { z } from 'zod';
import { localizableSchema } from '../localization';
import { KeepTypeAlias } from '../utilities';

export type MGAInformationMap<TMGAIds extends string> = { [mgaId in TMGAIds]: MGAInfo };

type MGAInfo = {
  name: string;
  isInterlocutor?: boolean;
};

export const mgaSchema = z.object({
  id: z.string(),
  name: localizableSchema,
  tag: z.string(),
  userGroupId: z.string().uuid(),
});

export type Mga = KeepTypeAlias<z.infer<typeof mgaSchema>>;
