import * as z from 'zod';

import { KeepTypeAlias } from '../utilities';

export const platformConfigSyncLogSchema = z.object({
  id: z.string().uuid(),
  from: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type PlatformConfigSyncLog = KeepTypeAlias<z.infer<typeof platformConfigSyncLogSchema>>;
