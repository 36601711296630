export enum CarrierSubmissionStatus {
  error = 'error',
  success = 'success',
  manuallySent = 'manuallySent',
}

export enum SubmissionSource {
  leadPlatform = 'lead-platform',
  consumerFlow = 'consumer-flow',
}
