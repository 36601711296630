import * as z from 'zod';

export enum Language {
  en = 'en',
  fr = 'fr',
}

export enum LanguageRegion {
  frCa = 'fr-CA',
  enCa = 'en-CA',
}

export type Localizable<T = string> = Record<Language, T>;
export type LocalizableList = Record<Language, string[]>;

export const localizableSchema = z.record(z.nativeEnum(Language), z.string());
