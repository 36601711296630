// from `OLI_LU_TRANS_TYPE_CODES` lookup in ACORD documentation
export enum TransTypeCode {
  newBusinessSubmission = '103',
}

// from `OLI_LU_GENDER` lookup in ACORD documentation
export enum GenderTypeCode {
  male = '1',
  female = '2',
  unisex = '3',
  combinedMaleFemale = '4',
  intersex = '5',
  femaleToMaleTransgender = '6',
  maleToFemaleTransgender = '7',
}

// from `OLI_LU_NONFORTPROV`
export enum NonForfeitProvisionTypeCode {
  none = '1',
  automaticPolicyLoan = '4',
}

export enum LineOfBusinessTypeCode {
  life = '1',
  annuity = '2',
}
export enum BackDateTypeCode {
  backDateToSaveAge = '1',
  noBackdating = '2',
}
// from OLI_LU_ORGFORM
export enum OrgForm {
  corporation = '23',
}

// from OLI_LU_TRUSTTYPE
export enum TrustType {
  unknown = '0',
}

export enum PriorNameTypeCode {
  nameType = '1',
}

export enum MeasureUnitsTypeCode {
  pounds = '13',
  kilos = '93',
}

export enum MeasurementUnitsTypeCode {
  metricSystem = '1',
  usSystemStandard = '2',
  alternateUSFormat = '3',
}

// from `OLI_LU_BOOLEAN` lookup in ACORD documentation
export enum BooleanTypeCode {
  false = '0',
  true = '1',
}

export enum SmokerTypeCode {
  smoker = '3',
  nonSmoker = '1',
}

export enum LifeCovTypeCode {
  accidentalDeathBenefit = '23',
}

export enum HoldingTypeCode {
  assetLiability = '1',
  banking = '7',
  businessLoan = '10',
  investment = '3',
  lineOfCredit = '11',
  mortage = '8',
  policy = '2',
  other = '2147483647',
  unknown = '0',
  sponsoredBenefitPlanMaster = '9',
  groupMasterContract = '6',
  package = '4',
}

export enum HoldingStatusTypeCode {
  proposedPending = '1',
}

export enum PolicyStatusTypeCode {
  appliedFor = '21',
  deathClaimPending = '10',
}

export enum PaymentRecurrenceTypeCode {
  annual = '1',
  biannual = '2',
  quarterly = '3',
  monthly = '4',
}

// from `OLI_LU_FINACTTYPE`
export enum FinancialActivityType {
  automaticPremiumLoan = '301',
  annuity = '7',
}

//Specific TypeCodes for FaithLife carrier
export enum EmploymentStatusTypeCode {
  active = '1',
  notActive = '2',
  retired = '8',
  other = '2147483647',
  // Specific TypeCodes for FaithLife carrier
  // TODO: find a solution to have this outside of the shared type
  student = '1045400004',
  currentlyLookingForWork = '2147000001',
  disability = '3',
  maternityLeaveOrAtHomeWithFamily = '15',
}
export enum BankAccountTypeCode {
  personal = '104540001',
  joint = '104540002',
  business = '104540003',
}

export enum PadDraftSelectionDateTypeCode {
  sameAsExistingFinancialPolicy = '1045400048',
  policyIssueDate = '1045400049',
  clientSelectedDate = '1045400060',
}

export enum BaseProductTypeCode {
  termInsurance = '180',
  termTo100 = '564',
  wholeLife = '903',
  wholeLife20Pay = '904',
  wholeLifeJoint = '905',
  wholeLife20PayJoint = '906',
}

// Specific TypeCodes for FaithLife carrier
// TODO: find a solution to have this outside of the shared type
export enum CoverageProductTypeCode {
  term10 = '181',
  term20 = '182',
  term30 = '183',
  termTo100 = '001',
  wholeLife20Pay = '001', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  wholeLife = '001', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  wholeLifeJoint = '905', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  wholeLife20PayJoint = '906', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  childTermBenefit = '021',
  totalDisabilityWaiver = '031',
  totalDisabilityWaiverInsured2 = '032',
  guaranteedInsurabilityOption = '061',
  guaranteedInsurabilityOptionInsured2 = '062',
  accidentalDeathBenefit = '018',
  accidentalDeathBenefitInsured2 = '019',
  ownerWaiverPremiumDeathDisability = '057',
  additionalDepositOption = '074',
  policyFee = '001', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
}

// from `OLI_LU_TOBPREMBASIS` lookup in ACORD documentation
export enum TobaccoPremiumBasisTypeCode {
  unknown = '0',
  nonSmoker = '1',
  smoker = '2',
  nicotineNonTobacco = '3',
  lowerRiskTobacco = '4',
  blended = '5',
}

// from `OLI_LU_POLPROD` lookup in ACORD documentation
export enum PolicyProductTypeCode {
  unknown = '0',
  wholeLife = '1',
  term = '2',
  universalLife = '3',
  variableUniversalLife = '4',
  indexedUniversalLife = '5',
  interestSensitiveWholeLife = '6',
  excessInterestLife = '7',
  variableWholeLife = '8',
  fixedAnnuity = '9',
  variableAnnuity = '10',
  indexedAnnuityNotOtherwiseSpecified = '11',
  incomeReplacement = '12',
  majorMedical = '13',
  hospitalIndemnity = '14',
  medicareSupplement = '15',
  dreadDisease = '16',
  longTermCare = '17',
  nursingHome = '18',
  hospitalSurgical = '19',
  medicalExpense = '20',
  cancer = '21',
  travelAccident = '22',
  overheadExpense = '23',
  disabilityBuyOut = '24',
  accidentHealth = '25',
  termWithCashValue = '26',
  criticalIllness = '30',
  creditMortgage = '32',
  openEndedInvestment = '33',
  funeralPolicy = '35',
  guaranteedInsurabilityPolicy = '37',
  openEndedInvestmentPolicyRetirement = '39',
  errorsOmissionCoverage = '40',
  indeterminatePremium = '41',
  auto = '42',
  home = '43',
  boat = '44',
  pelp = '45',
  combo = '46',
  homeHealthCare = '47',
  heartStroke = '48',
  intensiveCare = '49',
  shortTermCare = '50',
  accidentalDeathDismemberment = '51',
  shortTermDisability = '52',
  longTermDisability = '53',
  dental = '54',
  vision = '55',
  registeredIndexedAnnuity = '56',
  variablIndexedAnnuity = '57',
  fixedIndexedAnnuity = '58',
  package = '101',
  groupSuperannuation = '201',
  individualSuperannuation = '202',
  endowment = '302',
  retirementAnnuity = '301',
  deathIncomeStream = '303',
  disabilityLumpSum = '304',
  accidentalDeathOrDisabilityPolicy = '305',
  dreadDiseaseImpairmen = '306',
  preservationPensionFund = '307',
  preservationProvidentFund = '308',
  groupMasterContract = '400',
  experienceGroupPlan = '402',
  disabilityIncomeReplacementShortTerm = '408',
  disabilityIncomeReplacementLongTerm = '410',
  retrenchmentBenefit = '411',
  theSicknessPermanentIncapacity = '412',
  providentFund = '413',
  groupPension = '414',
  lifeLinkedInvestment = '415',
  retirementPlan = '416',
  other = '2147483647',
}

// from `OLI_LU_MARSTAT` lookup in ACORD documentation
export enum MaritalStatusTypeCode {
  civilUnion = '11',
  commonLaw = '6',
  divorced = '3',
  domesticPartner = '10',
  estranged = '7',
  legallySeparated = '5',
  married = '1',
  single = '2',
  widowed = '4',
  other = '2147483647',
  unknown = '0',
  headOfHousehold = '8',
  marriedWithHoldingSingle = '9',
  child = '1045400002',
}

//from `OLI_LU_GOVTIDTC` lookup in ACORD documentation
export enum GovtIDTypeCode {
  ageOfMajorityCard = '30',
  armedForcesID = '31',
  birthCertificate = '28',
  driversLicense = '29',
  genericForeignID = '10',
  GIIN = '26',
  healthMedicalCard = '27',
  identificationNumber = '25',
  passportNumber = '17',
  permanentResidentCard = '32',
  photoIDCard = '34',
  proofOfCitizenshipOrNaturalization = '35',
  recordOfLanding = '33',
  taxpayerIdentificationNumber = '2',
  ACNnumberAustralia = '5',
  ARBNNumberAustralia = '6',
  SISNumberAustralia = '7',
  canadianBusinessNumber = '11',
  canadianEmployerIdentificationNumber = '13',
  canadianQuebecEnterpriseNumber = '12',
  certificateOfIndianStatus = '37',
  oldAgeSecurityIdentification = '36',
  socialInsuranceNumberInCanada = '3',
  chineseMilitaryID = '21',
  other = '2147483647',
  unknown = '0',
  taxReferenceNumberSouthAfrica = '4',
  greenCard = '18',
  medicaidNumber = '20',
  medicareBeneficiaryIdentifier = '38',
  medicareNumber = '19',
  nationalProviderIdentifie = '22',
  railroadRetireeMedicareNumber = '23',
  railroadRetireeNumber = '24',
  socialSecurityNumber = '1',
  taxIDForUSNonResidentAlien = '9',
  usEmployerIdentificationNumber = '8',
}

export enum ClientLanguageTypeCode {
  english = '9',
  french = '12',
  arabic = '1',
  bengali = '69',
  cantonese = '523',
  farsi = '41',
  hindi = '57',
  japanese = '17',
  korean = '18',
  mandarin = '4',
  portuguese = '22',
  punjabi = '70',
  spanish = '10',
  tagalog = '569',
  tamil = '73',
  urdu = '32',
  vietnamese = '42',
  other = '2147483647',
}

//from `OLI_LU_IMMSTAT` lookup in ACORD documentation
export enum CitizenshipTypeCode {
  applicationForLandingImmigration = '6',
  citizen = '8',
  employee = '4',
  Immigrant = '7',
  nonLandedImmigrant = '9',
  permanentResident = '1',
  refugee = '10',
  refugeeClaimant = '5',
  student = '3',
  visitor = '2',
  other = '2147483647',
  unknown = '0',
}

// from `OLI_LU_NATION` lookup in ACORD documentation
export enum CountryTypeCode {
  afghanistan = '93',
  alandIslands = '248',
  albania = '355',
  algeria = '213',
  americanSamoa = '684',
  andorra = '376',
  angola = '244',
  anguilla = '1001',
  antarctica = '672',
  antiguaAndBarbuda = '268',
  argentina = '54',
  armenia = '374',
  aruba = '297',
  australia = '61',
  austria = '43',
  azerbaijan = '994',
  bahamas = '242',
  bahrain = '973',
  bangladesh = '880',
  barbados = '246',
  belarus = '375',
  belgium = '32',
  belize = '501',
  benin = '229',
  bermuda = '441',
  bhutan = '975',
  bolivia = '591',
  bonaireSintEustatiusAndSaba = '535',
  bosniaAndHerzegovina = '387',
  botswana = '267',
  bouvetIsland = '1009',
  brazil = '55',
  britishIndianOceanTerritory = '1011',
  bruneiDarussalam = '673',
  bulgaria = '359',
  burkinaFaso = '226',
  burundi = '257',
  cambodia = '855',
  cameroon = '237',
  canada = '2',
  capeVerde = '238',
  caymanIsland = '345',
  centralAfricanRepublic = '236',
  chad = '235',
  chile = '56',
  china = '86',
  christmasIsland = '1024',
  cocosIslands = '1007',
  colombia = '57',
  comoros = '269',
  congo = '1027',
  congoTheDemocraticRepublic = '271',
  cookIslands = '682',
  costaRica = '506',
  coteIvoire = '1003',
  croatia = '385',
  cuba = '53',
  curacao = '1026',
  cyprus = '357',
  czechRepublic = '420',
  denmark = '45',
  djibouti = '253',
  dominica = '767',
  dominicanRepublic = '809',
  ecuador = '593',
  egypt = '20',
  elSalvador = '503',
  equatorialGuinea = '240',
  eritrea = '1004',
  estonia = '372',
  ethiopia = '251',
  falklandIslands = '500',
  faroeIslands = '298',
  fiji = '679',
  finland = '358',
  france = '33',
  frenchGuiana = '594',
  frenchPolynesia = '689',
  frenchSouthernTerritories = '1015',
  gabon = '241',
  gambia = '220',
  georgia = '995',
  germany = '49',
  ghana = '233',
  gibraltar = '350',
  greece = '30',
  greenland = '299',
  grenada = '473',
  guadeloupe = '590',
  guam = '671',
  guatemala = '502',
  guernsey = '360',
  guinea = '224',
  guineaBissau = '270',
  guyana = '592',
  haiti = '509',
  heardIslandAndMcDonaldIslands = '1016',
  holySee = '1029',
  honduras = '504',
  hongKong = '852',
  hungary = '36',
  iceland = '354',
  india = '91',
  indonesia = '62',
  iran = '98',
  iraq = '964',
  ireland = '353',
  isleOfMan = '1035',
  israel = '972',
  italy = '39',
  jamaica = '876',
  japan = '81',
  Jersey = '1034',
  Jordan = '962',
  kazakhstan = '1005',
  kenya = '254',
  kiribati = '686',
  northKorea = '952',
  southKorea = '951',
  kosovo = '390',
  kuwait = '965',
  kyrgyzstan = '1006',
  laoPeopleDemocraticRepublic = '856',
  latvia = '371',
  lebanon = '961',
  lesotho = '266',
  liberia = '231',
  libya = '218',
  liechtenstein = '423',
  lithuania = '370',
  luxembourg = '352',
  macao = '853',
  macedonia = '389',
  madagascar = '261',
  malawi = '265',
  malaysia = '60',
  maldives = '1023',
  mali = '223',
  malta = '356',
  marshallIslands = '694',
  martinique = '596',
  mauritania = '222',
  mauritius = '230',
  mayotte = '1017',
  mexico = '52',
  micronesia = '695',
  moldova = '373',
  monaco = '1028',
  mongolia = '1008',
  montenegro = '1037',
  montserrat = '664',
  morocco = '212',
  mozambique = '258',
  myanmar = '950',
  namibia = '264',
  nauru = '674',
  nepal = '977',
  netherlands = '31',
  netherlandsAntilles = '599',
  newCaledonia = '687',
  newZealand = '64',
  nicaragua = '505',
  niger = '227',
  nigeria = '234',
  niue = '683',
  norfolkIsland = '1025',
  northYemen = '1038',
  northernMarianaIslands = '691',
  norway = '47',
  oman = '968',
  pakistan = '92',
  palau = '693',
  palestine = '1032',
  panama = '1002',
  papuaNewGuinea = '675',
  paraguay = '595',
  peru = '51',
  philippines = '63',
  pitcairn = '1018',
  poland = '48',
  portugal = '351',
  puertoRico = '510',
  qatar = '974',
  reunion = '262',
  romania = '40',
  russianFederation = '7',
  rwanda = '250',
  saintBarthelemy = '652',
  saintHelena = '290',
  saintKittsandNevis = '869',
  saintLucia = '758',
  saintMartin = '663',
  saintPierreAndMiquelon = '508',
  saintVincentAndTheGrenadines = '784',
  samoa = '1013',
  sanMarino = '378',
  saoTomeAndPrincipe = '239',
  saudiArabia = '966',
  senegal = '221',
  serbia = '1036',
  seychelles = '1010',
  sierraLeone = '232',
  singapore = '65',
  sintMaarten = '534',
  slovakia = '421',
  slovenia = '386',
  solomonIslands = '677',
  somalia = '252',
  southAfrica = '27',
  southGeorgia = '512',
  southSudan = '728',
  southYemen = '1039',
  spain = '34',
  sriLanka = '94',
  sudan = '249',
  suriname = '597',
  svalbardAndJanMayen = '1019',
  swaziland = '1031',
  sweden = '46',
  switzerland = '41',
  syrianArabRepublic = '963',
  taiwan = '886',
  tajikistan = '992',
  tanzania = '255',
  thailand = '66',
  timorLeste = '1014',
  togo = '228',
  tokelau = '1020',
  tonga = '676',
  trinidadAndTobago = '868',
  tunisia = '216',
  turkey = '90',
  turkmenistan = '993',
  turksandCaicosIslands = '649',
  tuvalu = '688',
  uganda = '256',
  ukraine = '380',
  unionOfSovietSocialistRepublics = '1040',
  unitedArabEmirate = '971',
  unitedKingdom = '44',
  unitedStatesMinorOutlyingIslands = '1021',
  unitedStates = '1',
  uruguay = '598',
  uzbekistan = '998',
  vanuatu = '678',
  venezuela = '58',
  vietNam = '84',
  virginIslandsBritish = '284',
  virginIslandsUS = '511',
  wallisAndFutuna = '681',
  westernSahara = '1022',
  yemen = '967',
  yugoslavia = '38',
  zambia = '260',
  zimbabwe = '263',
  other = '2147483647',
  unknown = '0',
}

export enum ReplacePolicyType {
  replaced = '1',
}

export type JurisdictionTypeCode = CanadianProvinceTypeCode | UsStateTypeCode;

//from `OLI_LU_STATE` lookup in ACORD documentation
export enum CanadianProvinceTypeCode {
  AB = '101',
  BC = '102',
  NL = '105',
  MB = '103',
  NB = '104',
  NT = '106',
  NS = '107',
  NU = '113',
  ON = '108',
  PE = '109',
  QC = '110',
  SK = '111',
  YT = '112',
  other = '2147483647',
  unknown = '0',
}

//from `OLI_LU_STATE` lookup in ACORD documentation
export enum UsStateTypeCode {
  AL = '1',
  AK = '2',
  AZ = '4',
  AR = '5',
  CA = '6',
  CO = '7',
  CT = '8',
  DE = '9',
  FL = '12',
  GA = '13',
  HI = '15',
  ID = '16',
  IL = '17',
  IN = '18',
  IA = '19',
  KS = '20',
  KY = '21',
  LA = '22',
  ME = '23',
  MD = '25',
  MA = '26',
  MI = '27',
  MN = '28',
  MS = '29',
  MO = '30',
  MT = '31',
  NE = '32',
  NV = '33',
  NH = '34',
  NJ = '35',
  NM = '36',
  NY = '37',
  NC = '38',
  ND = '39',
  OH = '41',
  OK = '42',
  OR = '43',
  PA = '45',
  RI = '47',
  SC = '48',
  SD = '49',
  TN = '50',
  TX = '51',
  UT = '52',
  VT = '53',
  VA = '55',
  WA = '56',
  WV = '57',
  WI = '58',
  WY = '59',
  other = '2147483647',
  unknown = '0',
}

export enum PartyTypeCode {
  person = '1',
  organization = '2',
  unknown = '0',
}

export enum IdentityVerificationTypeCode {
  passport = '2',
  driversLicense = '1',
  stateID = '10',
  permanentResidentCard = '1045400013',
  citizenshipCard = '14',
  secureCertificateOfIndianStatus = '15',
}

export enum PrivacyAffiliateTypeCode {
  OLI_SHAREDATATYPE_NOCONTACT = '6',
  OLI_SHAREDATATYPE_NEWCLIENT = '5',
  OLI_SHAREDATATYPE_IN = '1',
  OLI_SHAREDATATYPE_OUTCARIRE = '3',
  OLI_SHAREDATATYPE_OUTCLIENT = '2',
  OLI_SHAREDATATYPE_OUTSTATE = '4',
  OLI_OTHER = '2147483647',
  OLI_UNKNOWN = '0',
}

export enum AddressTypeCode {
  residential = '1',
  mailing = '17',
  other = '2147483647',
  unknown = '0',
  previous = '29',
  business = '2',
}

export enum TelephoneTypeCode {
  cellularTelephoneType = '12',
  homeTelephoneType = '1',
  workTelephoneType = '2',
  faxTelephoneType = '4',
  otherTelephoneType = '2147483647',
}

export enum CallTimeTypeCode {
  morning = '1045400039',
  afternoon = '1045400040',
  evening = '1045400041',
  anyTime = '1045400038',
  dayTime = '1045400042',
}

export enum BestDayToCallTypeCode {
  anyDay = '12',
  weekdays = '6',
  monday = '1',
  tuesday = '2',
  wednesday = '3',
  thursday = '4',
  friday = '5',
  saturday = '8',
  sunday = '9',
  other = '2147483647',
  unknown = '0',
}

export enum RelationDescription {
  aunt = '31',
  brother = '7',
  brotherInLaw = '25',
  commonLawHusband = '62',
  commonLawWife = '61',
  cousin = '29',
  daughter = '6',
  daughterInLaw = '24',
  formerSpouse = '174',
  father = '3',
  fatherInLaw = '21',
  fiance = '60',
  fiancee = '90',
  boyFriend = '88',
  girlFriend = '89',
  goddaughter = '56',
  godson = '55',
  godfather = '34',
  godmother = '35',
  granddaughter = '20',
  grandson = '19',
  grandfather = '17',
  grandmother = '18',
  husband = '1',
  mother = '4',
  motherInLaw = '22',
  nephew = '32',
  niece = '33',
  other = '2147483647',
  self = '91',
  sister = '8',
  sisterInLaw = '26',
  son = '5',
  sonInLaw = '23',
  stepdaughter = '14',
  stepson = '13',
  stepfather = '12',
  stepmother = '11',
  uncle = '30',
  wife = '2',
  unknown = '0',
}

// Custom RelationDescription for FLF
export enum FlfRelationDescription {
  child = '1045400013',
  partner = '1045400009',
  employer = '1045400011',
  executor = '1045400012',
  legalGuardian = '1045400010',
  friend = '1045400008',
  spouse = '1045400016',
  stepChild = '1045400014',
  sibling = '1045400015',
}

/** 
    This type is an aggregator of each Custom RelationDescription for use on other shared types
    If we need to add new custom for new carrier, we just need create new RelationDescription (ex BncRelationDescription)
    And add it to the custom type (RelationDescription | FlfRelationDescription | BncRelationDescription)
*/
export type CustomRelationDescription = RelationDescription | FlfRelationDescription;

export enum IrrevokableIndTypeCode {
  false = '0',
  true = '1',
}

export enum PaymentMethodTypeCode {
  payrollDeduction = '6',
  annualCheque = '2',
  preAuthorizedDebit = '7',
  existingPAD = '1',
  other = '2147483647',
  unknown = '0',
  annually = '2', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  monthly = '7', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
  quarterly = '2', // eslint-disable-line @typescript-eslint/no-duplicate-enum-values
}
export enum PaymentModeTypeCode {
  annually = '1',
  monthly = '4',
  quarterly = '3',
  semiAnnually = '2',
}

export enum RelationRoleCode {
  owner = '8',
  agent = '11',
  payer = '31',
  insured = '32',
  primaryBeneficiary = '34',
  annuitant = '35',
  contigentBeneficiary = '36',
  primaryWritingAgent = '37',
  serviceAgent = '38',
  generalAgent = '48',
  additionalWritingAgent = '52',
  physician = '57',
  jointOwner = '184',
  jointInsured = '189',
  jointAnnuitant = '183',
  trustee = '69',
  child = '2',
  unknown = '0',
}

export enum ObjectType {
  party = '6',
  holding = '4',
  formImstance = '101',
}

export enum IndicatorTypeCode {
  base = '1',
  rider = '2',
  unknown = '0',
}

export enum DividendsTypeTypeCode {
  paidUpAdditions = '3',
  dividendAccumulation = '5',
  paidInCash = '2',
  loadReduction = '26',
  premiumReduction = '4',
}

// TODO: HOT-3392 investigate why beneficiary codes are wrong and use only ParticipantRoleTypeCode that is according to ACORD OLI_LU_PARTICROLE
export enum LifeParticipantRoleTypeCode {
  insured = '1',
  beneficiary = '34',
  contingent = '36',
  insuredChild = '3',
  trustee = '19',
}

export enum ParticipantRoleTypeCode {
  insured = '1',
  annuitant = '27',
  beneficiary = '7',
  contingent = '9',
  insuredChild = '3',
  trustee = '19',
}

export enum DocumentCategoryTypeCode {
  unknown = '0',
  statement = '1',
}

export enum IdReferenceTypeTypeCode {
  carrierAssignedPIN = '33',
}

// PolicyProductTypeCode (above): OLI_LU_POLPROD (Policy Product Type) contains other annuity products
// OLI_LU_QUALPLAN (Qualified Plan Type) contains the following annuity products with different typecodes
// The following is likely to use codes that are specific to SereniaLife
export enum AnnuitiesProductCodeTypeCode {
  nonRegistered = '725',
  rrsp = '727',
  tfsa = '729',
  rrif = '731',
}

export enum AnnuitiesProductCodeQualifiedCode {
  nonRegistered = '1',
  rrsp = '2',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  tfsa = '2',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  rrif = '2',
}

export enum AnnuitiesProductTypeTypeCode {
  investment = '2',
}

export enum TaxWithholdingTypeCode {
  notWitheld = '4',
  percent = '6',
  dollar = '8',
  unknown = '0',
}

export enum MinimumAmtIndTypeCode {
  no = '0',
  yes = '1',
}

export enum WithdrawalTaxTreatmentTypeCode {
  beforeApplicableTaxes = '2',
  afterApplicableTaxes = '1',
}

export enum PayoutFormTypeCode {
  cheque = '10',
  eft = '7',
}

export enum AutoSweepTypeCode {
  disabled = '0',
  enabled = '1',
}

export enum ReplacementTypeCode {
  both = '4',
  external = '3',
  internal = '2',
  none = '1',
}
