export enum QuestionnairePreview {
  consumerFlow = 'consumerFlow',
  assistedApplication = 'assistedApplication',
  pdf = 'pdf',
}

export type QuestionnairePreviewData = {
  questionnaireVersionId: string;
  previewType: QuestionnairePreview;
};

export type QuestionnairePreviewQuery = {
  questionnaireVersionId: string;
  needsAnalysis?: boolean;
  previewType: QuestionnairePreview;
};

export type ExistingLeadAndApplicationPreview = {
  leadId: number;
  applicationId: string;
};
