export enum Permission {
  // TODO: Remove ApplicationLaunch once every carrier are in prod for at least 1 day with the new
  // ApplicationLaunchMe and ApplicationLaunchAll permissions.
  ApplicationLaunch = 'application:launch',
  ApplicationLaunchMe = 'application:launch:me',
  ApplicationLaunchAll = 'application:launch:all',
  ApplicationRead = 'application:read',
  ApplicationReadAll = 'application:read:all',
  ApplicationCreate = 'application:create',
  ApplicationUpdate = 'application:update',
  ApplicationSendDeclaration = 'application:send-declaration',

  ApplicationSignatureRead = 'applicationSignature:read',

  ESignNotificationRead = 'esignNotification:read',
  ESignNotificationUpdate = 'esignNotification:update',
  ESignNotificationRemove = 'esignNotification:remove',

  ESignSettingCreate = 'eSignSetting:create',
  ESignSettingUpdate = 'eSignSetting:update',
  ESignSettingRead = 'eSignSetting:read',
  ESignSettingRemove = 'eSignSetting:remove',

  EsignAdhocRequestCreate = 'EsignAdhocRequest:create',
  EsignAdhocRequestRead = 'EsignAdhocRequest:read',
  EsignAdhocRequestUpdate = 'EsignAdhocRequest:update',
  EsignAdhocRequestDelete = 'EsignAdhocRequest:delete',

  InsuranceFirmCreate = 'insuranceFirm:create',
  InsuranceFirmUpdate = 'insuranceFirm:update',
  InsuranceFirmRead = 'insuranceFirm:read',

  InsuranceProductCreate = 'insuranceProduct:create',
  InsuranceProductUpdate = 'insuranceProduct:update',
  InsuranceProductRead = 'insuranceProduct:read',

  InsuranceProductPricingCreate = 'InsuranceProductPricingCreate:create',

  IntegrationsHealthRead = 'integrationsHealth:read',

  LeadCreate = 'lead:create',
  LeadDelete = 'lead:delete',
  LeadEditContacted = 'lead:edit:contacted',
  LeadArchive = 'lead:archive',
  LeadReadMe = 'lead:read:me',
  LeadReadGroup = 'lead:read:group',
  LeadReadGroupMGA = 'lead:read:group:mga',
  LeadReadAll = 'lead:read:all',
  LeadInvitePublic = 'lead:invite:public',
  LeadInvitePrivate = 'lead:invite:private',
  LeadInviteEmail = 'lead:invite:email',
  LeadAssignRead = 'lead:assign:read',
  LeadAssignMe = 'lead:assign:me',
  LeadAssignGroup = 'lead:assign:group',
  LeadAssignAll = 'lead:assign:all',
  LeadUnassignMe = 'lead:unassign:me',
  LeadUnassignAll = 'lead:unassign:all',

  PdfSummaryRead = 'pdfSummary:read',

  SignatureRequestCreate = 'signatureRequest:create',
  SignatureRequestRead = 'signatureRequest:read',
  SignatureRequestRemove = 'signatureRequest:remove',
  SignatureRequestUpdate = 'signatureRequest:update',
  SignatureRequestEventRead = 'signatureRequestEvent:read',
  SubmissionUpdate = 'submission:update',
  ManualSubmission = 'submission:manual',

  UserEditSelf = 'user:edit:self',
  UserEditAll = 'user:edit:all',
  UserCreate = 'user:create',
  UserBulkCreate = 'user:bulk:create',
  UserCreateSuperAdmin = 'user:create:superAdmin',
  UserListAll = 'user:list:all',
  UserRead = 'user:read',
  UserSyncAll = 'user:sync:all',
  UserClearMetadata = 'user:metadata:remove',
  AdminToolsRead = 'adminTools:read',

  QuestionnaireCreate = 'questionnaire:create',
  QuestionnaireRead = 'questionnaire:read',
  QuestionnaireUpdate = 'questionnaire:update',
  QuestionnaireRemove = 'questionnaire:remove',
  QuestionnairePreview = 'questionnaire:preview',
  QuestionnairePartRead = 'questionnairePart:read',

  SalesDecisionRuleCreate = 'salesDecisionRule:create',
  SalesDecisionRuleRead = 'salesDecisionRule:read',
  SalesDecisionRuleUpdate = 'salesDecisionRule:update',
  SalesDecisionRuleRemove = 'salesDecisionRule:remove',

  ThemeCreate = 'theme:create',
  ThemeRead = 'theme:read',
  ThemeUpdate = 'theme:update',
  ThemeDelete = 'theme:delete',

  SettingsCreate = 'settings:create',
  SettingsRead = 'settings:read',
  SettingsUpdate = 'settings:update',

  ApplicationFileCreate = 'applicationFile:create',
  ApplicationFileRead = 'applicationFile:read',
  ApplicationFileUpdate = 'applicationFile:update',
  ApplicationFileRemove = 'applicationFile:remove',

  FileTemplateCreate = 'fileTemplate:create',
  FileTemplateRead = 'fileTemplate:read',
  FileTemplateUpdate = 'fileTemplate:update',
  FileTemplateRemove = 'fileTemplate:remove',

  FileTemplateRuleCreate = 'fileTemplateRule:create',
  FileTemplateRuleRead = 'fileTemplateRule:read',
  FileTemplateRuleUpdate = 'fileTemplateRule:update',
  FileTemplateRuleRemove = 'fileTemplateRule:remove',

  FileCreate = 'file:create',
  FileRead = 'file:read',
  FileUpdate = 'file:update',
  FileRemove = 'file:remove',

  ThirdPartyIntegrationConfigurationEdit = 'thirdPartyIntegration:edit',
  ThirdPartyIntegrationConfigurationRead = 'thirdPartyIntegration:read',

  ApplicationSupportSignaturesRead = 'applicationSupport:signatures:read',
  ApplicationSupportUnderwritingOutcomeReportsRead = 'applicationSupport:underwritingOutcomeReports:read',
  ApplicationSupportApplicationsRead = 'applicationSupport:applications:read',
  ApplicationSupportApplicationResetSubmittedStatus = 'applicationSupport:application:reset-submitted-status',
  ApplicationSupportPIIPHIRead = 'applicationSupport:PIIPHI:read',

  AgentLicenseCreate = 'agentLicense:create',
  AgentLicenseRead = 'agentLicense:read',

  ApplicationMarketingDataExtract = 'applicationMarketingData:extract:create',
  ApplicationCopyCreate = 'applicationCopy:create',

  JetDecisionWidgetView = 'jetDecisionWidget:view',

  PlatformConfigurationSync = 'platformConfiguration:sync',
  PlatformConfigurationRead = 'platformConfiguration:read',

  AuditLogsRead = 'auditLog:read',

  PaperApplicationRead = 'paperApplication:read',
  PaperApplicationCreate = 'paperApplication:create',
  PaperApplicationUpdate = 'paperApplication:update',

  CronjobRun = 'cronjob:run',

  LinesOfBusinessRead = 'linesOfBusiness:read',

  NotificationRead = 'notification:read',
  NotificationUpdate = 'notification:update',
  NotificationCreate = 'notification:create',

  PricingFieldIdentifierRead = 'pricingFieldIdentifier:read',
  PricingFieldIdentifierUpdate = 'pricingFieldIdentifier:update',

  // Message-dispatcher must have this permission so send HTTP request to the backend
  BackendEventsProcess = 'backendEvents:process',
}
