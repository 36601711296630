import { ESignDocument } from './eSignDocument';
import { ESignProviderPackage } from './eSignProviderPackage';
import { ESignSigner, ESignSigner2FAInfo } from './eSignSigner';

export enum ESignCeremonyStatus {
  /**
   * Ceremony in draft for application
   */
  DRAFT = 'DRAFT',
  /**
   * Document generation requested
   */
  IN_PROGRESS = 'IN_PROGRESS',
  /**
   * Documents generated and ceremony ready to send to signing provider
   */
  READY = 'READY',
  /**
   * Ceremony sent to signing provider
   */
  SENT = 'SENT',
  /**
   * Ceremony signed and completed
   */
  COMPLETED = 'COMPLETED',
  /**
   * Ceremony cancelled
   */
  CANCELLED = 'CANCELLED',

  // Errors
  EMAIL_BOUNCED = 'EMAIL_BOUNCED',
  OPT_OUT = 'OPT_OUT',
  DECLINED = 'DECLINED',
  SIGNER_LOCKED_OUT = 'SIGNER_LOCKED_OUT',
  EXPIRED = 'EXPIRED',
  SEND_PACKAGE_ERROR = 'SEND_PACKAGE_ERROR',
}

export type ESignCeremony = {
  id: string;
  applicationId: string;
  eSignDocuments?: ESignDocument[];
  eSignProviderPackage?: ESignProviderPackage;
  eSignSigners?: ESignSigner[];
  createdAt: Date;
  updatedAt: Date;
  status: ESignCeremonyStatus;
};

export type ReplacedESignCeremony = ESignCeremony & {
  newESignCeremony: ESignCeremony;
};

// ESignCeremony service request types
export type ESignCeremonyPatchRequestData =
  | {
      status: ESignCeremonyStatus.IN_PROGRESS;
      signers: ESignSigner2FAInfo[];
    }
  | {
      status: ESignCeremonyStatus.CANCELLED;
    }
  | {
      status: ESignCeremonyStatus.SENT;
      signers: ESignSigner2FAInfo[];
    }
  | {
      status: ESignCeremonyStatus.DRAFT;
    };
