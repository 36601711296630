// TODO: DEV-9246 merge attachment table into stored-files table
import { SupportedFileExtType, SupportedFileMimeType } from './storedFile';

/** @deprecated Use `StoredFile` as the source of truth for uploaded documents */
// NOTE: This is NOT compatible with the Attachment type in the LCP/advisor
// backend which uses `insuranceApplicationId` to join with the applications models
export type Attachment = {
  id: string;
  applicationId: string;
  displayName: string;
  mimeType: SupportedAttachmentMimeType;
  encoding: string;
  sizeBytes: number;
  archived: boolean;
  storageFileName: string;
  storageBucketName: string;
  storageUrl: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

/** @deprecated Use `StoredFile` as the source of truth for uploaded documents */
export type AttachmentWithSignedUrl = Attachment & { signedUrl: string };

export const supportedAttachmentMimeTypes = [
  SupportedFileMimeType.pdf,
  SupportedFileMimeType.jpg,
  SupportedFileMimeType.png,
];
export type SupportedAttachmentMimeType = (typeof supportedAttachmentMimeTypes)[number];

export const supportedAttachmentExtTypes = [
  SupportedFileExtType.pdf,
  SupportedFileExtType.jpg,
  SupportedFileExtType.png,
];
export type SupportedAttachmentExtType = (typeof supportedAttachmentExtTypes)[number];
