import { OutcomeCode } from '../carrierQuestionnaire/outcome';
import { UnderwritingDataProvider, UnderwritingDataReportCategory } from '../underwritingData';

export type ApplicationOutcome = {
  id: string;
  applicationId: string;
  participantId: string;
  outcomeCode: OutcomeCode;
  type: ApplicationOutcomeType | UnderwritingDataReportCategory; // Remove Underwriting...Category after HOT-2657
  provider: UnderwritingDataProvider;
  applicationUnderwritingReportId?: string;
};

export type ApplicationOutcomesPerParticipantId = Record<string, ApplicationOutcome[]>;

// TODO: Update the dashed case value to camel case

// TODO: combine with ApplicationOutcomeType to avoid duplication
// https://breathelife.atlassian.net/browse/HOT-2657
export enum ApplicationOutcomeType {
  SalesDecision = 'sales-decision',
  MedicalHistory = 'medicalHistory',
  MIB = 'mib',
  IdentityCheck = 'identityCheck',
  MVR = 'mvr',
  Prescription = 'prescription',
}
