type TypeInvolvedInPublication = 'blueprint' | 'structure' | 'saleDecisionsRules' | 'fileTemplateRules'; // TODO: Add enQuestionnaire, frQuestionnaire later.

export class TypeIsUnsafeForPublication extends Error {
  readonly tag = 'TypeIsUnsafeForPublication';
  readonly type: TypeInvolvedInPublication;

  constructor(type: TypeInvolvedInPublication, msg: string) {
    super(msg);
    this.type = type;
  }
}

export type PublishQuestionnaireVersionResponse =
  | { tag: 'success' }
  | { tag: 'error'; type: TypeInvolvedInPublication; message: string };
