import * as z from 'zod';

export const stripeTransactionSchema = z.object({
  id: z.string(),
  setupIntentId: z.string(),
  subscriptionId: z.string().optional(),
  paymentTransactionId: z.string().optional(),
  applicationId: z.string().optional(),
  createdAt: z.preprocess((arg) => (typeof arg == 'string' ? new Date(arg) : undefined), z.date()),
});

export type StripeTransaction = z.infer<typeof stripeTransactionSchema>;
