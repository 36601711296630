import { FieldTypes } from './fieldTypes';

export const QuestionnaireScreenDefaultFieldName = 'Default';

export enum QuestionnaireScreen {
  PaperApp = 'PaperApp',
}

export type QuestionnaireScreenFieldConfig = {
  optional?: boolean;
  validationType?: string;
  skipValidityConditions?: boolean;
};

export type QuestionnaireScreenConfig = {
  [fieldTypeName in FieldTypes]?: QuestionnaireScreenFieldConfig;
} & {
  [QuestionnaireScreenDefaultFieldName]: QuestionnaireScreenFieldConfig;
  mockCapabilityForInternalUsers?: boolean;
};

export type QuestionnaireScreensConfig = {
  [screenKey in QuestionnaireScreen]?: QuestionnaireScreenConfig;
};
