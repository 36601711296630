import { z } from 'zod';
import { KeepTypeAlias } from '../utilities';
import { themeArraySchema } from '../themes';
import { mgaSchema } from '../mgas';

export const userGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  theme: themeArraySchema.optional(),
  mga: mgaSchema.optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type UserGroup = KeepTypeAlias<z.infer<typeof userGroupSchema>>;
