import { UserRole } from '../users/roles';
import { User } from '../users/users';

export enum AdminToolResourceName {
  salesDecisionRule = 'salesDecisionRule',
  product = 'product',
  productPricing = 'productPricing',
  firm = 'firm',
  theme = 'theme',
  settings = 'settings',
  questionnaire = 'questionnaire',
  thirdPartyIntegrations = 'thirdPartyIntegrations',
  submittedApplicationStatusReset = 'submittedApplicationStatusReset',
}

export enum AdminToolActionType {
  create = 'create',
  update = 'update',
  read = 'read',
  delete = 'delete',
  import = 'import',
  patch = 'patch',
}

export type BaseAuditLog = {
  resourceName: AdminToolResourceName;
  resourceId: string;
  actionType: AdminToolActionType | null;
  actionDetail: Record<string, any> | null;
  userId: string;
  userRoles: UserRole[];
  user?: Pick<User, 'firstName' | 'lastName' | 'emailLogin'>;
};

export type AuditLog = BaseAuditLog & {
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type AuditLogsRowData = Omit<AuditLog, 'id' | 'userId' | 'updatedAt'>;
