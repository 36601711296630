export enum OutcomeCode {
  denied = 'denied',
  referred = 'referred',
  accepted = 'accepted',
  conditional = 'conditional',
  blocked = 'blocked',
  blockedAtQuoter = 'blockedAtQuoter',
  unknown = 'unknown',
  ignored = 'ignored',
  notApplicable = 'notApplicable',
}

export type OutcomeCodePerParticipant = {
  participantId: string;
  outcome: OutcomeCode;
  applicationUnderwritingReportId?: string;
};
