import * as z from 'zod';

export const paymentCustomerDataSchema = z.object({
  leadId: z.number(),
  providerCustomerId: z.string(),
});

export const paymentCustomerSchema = paymentCustomerDataSchema.extend({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type PaymentCustomerData = z.infer<typeof paymentCustomerDataSchema>;
export type PaymentCustomer = z.infer<typeof paymentCustomerSchema>;
