export enum UserRole {
  superAdmin = 'superAdmin', // Breathe Life internal user
  supportAdmin = 'supportAdmin', // Has the capability to view PHI/PII data on the Admin Support Tools
  supportManager = 'supportManager', // Breathe Life support users that can reset and otherwise modify an application status
  supportAnalyst = 'supportAnalyst', // Breathe Life support users that can only observe
  admin = 'admin', // Carrier admin user
  manager = 'manager', // Managing General Agent which manage a subset of advisors
  user = 'user', // Regular advisor / agent role
  viewer = 'viewer', // Read only role
  groupManager = 'groupManager', // admin-like role but linked to a specific group. Related to the co-branding feature.

  // BL internal service roles
  cruncher = 'cruncher',
  pdfGenerator = 'pdfGenerator',
  messageQueue = 'messageQueue',
  consumerFlow = 'consumerFlow',
}

export const USER_ROLES_WITH_ASSIGNABLE_PERMISSION_VALUES: {
  [role in UserRolesWithAssignablePermissions]: UserRole;
} = {
  viewer: UserRole.viewer,
  user: UserRole.user,
  manager: UserRole.manager,
  admin: UserRole.admin,
  supportAdmin: UserRole.supportAdmin,
  supportAnalyst: UserRole.supportAnalyst,
  supportManager: UserRole.supportManager,
  superAdmin: UserRole.superAdmin,
  groupManager: UserRole.groupManager,
};

export type UserRolesWithAssignablePermissions =
  | UserRole.superAdmin
  | UserRole.supportAdmin
  | UserRole.supportAnalyst
  | UserRole.supportManager
  | UserRole.admin
  | UserRole.manager
  | UserRole.user
  | UserRole.viewer
  | UserRole.groupManager;

export type ServiceRoles = UserRole.cruncher | UserRole.pdfGenerator | UserRole.messageQueue | UserRole.consumerFlow;
