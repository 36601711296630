import { Localizable } from '../localization';

export enum ThirdPartyIntegrationStatus {
  active = 'active',
  inactive = 'inactive',
}

export type ThirdPartyIntegration = {
  id: string;
  title: Localizable;
  description: Localizable;
  status: ThirdPartyIntegrationStatus;
  config: unknown; // This is temporary, we don't yet have visibility over what this will be
};

export type LocalizedThirdPartyIntegration = {
  id: string;
  title: string;
  description: string;
  status: ThirdPartyIntegrationStatus;
  config: unknown; // This is temporary, we don't yet have visibility over what this will be
};

export type ThirdPartyIntegrationConfigModification = {};
