import { Infer, t } from 'typegate';
import { DateUnit, ShiftMagnitude, timeRoudingType } from './dateTime';

export enum QueryOperator {
  sum = 'sum',
  subtract = 'subtract',
  multiply = 'multiply',
  subtractDates = 'subtractDates',
  formula = 'formula',
  countEqual = 'countEqual',
  fetch = 'fetch',
  fetchSpecificId = 'fetchSpecificId',
  shiftDateBackwards = 'shiftDateBackwards',
  shiftDateForwards = 'shiftDateForwards',
}

export const queryOperatorParameters = t.object(
  t.optionalProperty('numeric', t.array(t.union(t.number, t.string))),
  t.optionalProperty('formula', t.string),
  t.optionalProperty('formulaParams', t.any),
  t.optionalProperty('dateUnit', t.enum(DateUnit)),
  t.optionalProperty('controlValue', t.unknown),
  t.optionalProperty('fetch', t.object(t.property('firstNonEmptyValueAt', t.array(t.string)))),
  t.optionalProperty(
    'fetchSpecificId',
    t.object(t.property('specificValueAt', t.string), t.property('matchValue', t.string)),
  ),
  t.optionalProperty(
    'shiftDateParams',
    t.object(
      t.property('dateFormat', t.string),
      t.property('timeRoundingType', timeRoudingType),
      t.property('shiftValue', t.number),
      t.optionalProperty('shiftMagnitude', t.enum(ShiftMagnitude)),
    ),
  ),
);

export type QueryOperatorParameters = Infer<typeof queryOperatorParameters>;

export type OperatorValue = number | string | boolean;
export type OperatorResult = number | string | boolean | undefined;

enum QueryOperatorNames {
  sum = 'sum',
  subtract = 'subtract',
  multiply = 'multiply',
  subtractDates = 'subtractDates',
  formula = 'formula',
  countEqual = 'countEqual',
  fetch = 'fetch',
  fetchSpecificId = 'fetchSpecificId',
  shiftDateBackwards = 'shiftDateBackwards',
  shiftDateForwards = 'shiftDateForwards',
}

type OperatorFunction = (values: Record<string, OperatorValue>, params?: QueryOperatorParameters) => OperatorResult;
export type QueryOperators = Record<QueryOperatorNames, OperatorFunction>;
