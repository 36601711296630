export type CarrierSpecificData = CarrierData & {
  id: string;
  createdAt: string;
  updatedAt: string;
};

export enum CarrierSpecificDataTag {
  salaryDeduction = 'salaryDeduction',
}

export type CarrierData = {
  tag: string;
  data: unknown;
};
