import * as z from 'zod';

import { paymentCustomerSchema } from './paymentCustomer';
import { PaymentServiceProvider } from './paymentServiceProvider';
import { stripeTransactionSchema } from './stripe';

export const paymentTransactionDataSchema = z.object({
  applicationId: z.string(),
  customerId: z.string(),
  paymentServiceProvider: z.nativeEnum(PaymentServiceProvider),
});

const paymentTransactionSchema = paymentTransactionDataSchema
  .extend({
    id: z.string(),
    paymentCustomer: paymentCustomerSchema.optional(),
    paymentToken: z.record(z.any()).optional().nullable(),
    createdAt: z.date(),
    updatedAt: z.date(),
  })
  .and(
    z.discriminatedUnion('paymentServiceProvider', [
      z.object({
        paymentServiceProvider: z.literal(PaymentServiceProvider.STRIPE),
        stripeTransaction: stripeTransactionSchema.optional(),
      }),
      z.object({
        // Clover is not implemented in core.
        paymentServiceProvider: z.literal(PaymentServiceProvider.CLOVER),
      }),
    ]),
  );

export type PaymentTransactionData = z.infer<typeof paymentTransactionDataSchema>;
export type PaymentTransaction = z.infer<typeof paymentTransactionSchema>;
