// NOTE: These values are sometimes used directly in MIB calls.
export enum ProductType {
  wholeLife = 'wholeLife',
  termLife = 'termLife',
  criticalIllness = 'criticalIllness',
  // Note that this is not an exhaustive list
}

// NOTE: These values are sometimes used directly in MIB calls.
export enum HoldingForm {
  individual = 'life',
  jointIndividual = 'jointIndividual',
  // Note that this is not an exhaustive list
}

export enum SupportedBuyer {
  pro = 'pro',
  consumer = 'consumer',
}
