import { failure, Result, success } from '@breathelife/result';

export class ReferenceLabelValidationError extends Error {
  // @ts-expect-error
  private readonly tag: 'ReferenceLabelValidationError';
}

export class ReferenceLabel {
  // The private readonly tag prevents callers for using the 'as' keyword to cast unvalidated ReferenceLabel value.
  // Removing this property would be less safe.
  // @ts-ignore
  private readonly tag: 'ReferenceLabel';
  private readonly _value: string;

  private constructor(validatedReferenceLabel: string) {
    this.tag = 'ReferenceLabel';
    this._value = validatedReferenceLabel;
  }

  get value(): string {
    return this._value;
  }

  static create(referenceLabel: string): Result<ReferenceLabelValidationError, ReferenceLabel> {
    if (!this.isValidPrefix(referenceLabel)) {
      const msg = `The reference label '${referenceLabel}' is invalid. Reference labels must begin with a letter.`;
      return failure(new ReferenceLabelValidationError(msg));
    }

    if (!this.isValidCharacters(referenceLabel)) {
      const msg = `The reference label '${referenceLabel}' is invalid. Reference labels must contain only letters, numbers and underscore.`;
      return failure(new ReferenceLabelValidationError(msg));
    }

    return success(new ReferenceLabel(referenceLabel));
  }

  static isValidCharacters(referenceLabel: string): boolean {
    const numbersLettersAndUnderscores = /^[a-zA-Z0-9_]+$/;
    return numbersLettersAndUnderscores.test(referenceLabel);
  }

  static isValidPrefix(referenceLabel: string): boolean {
    const startsWithLetter = /^[a-zA-Z].*/;
    return startsWithLetter.test(referenceLabel);
  }
}
