import { z } from 'zod';
import { LogoFiletype } from './logoFiletype';
import { NavigationSidebarVariants } from './navigationSidebarVariants';
import { colorRangesSchema } from './colors';

const userGroupSchema = z.object({ name: z.string() });
export const themeSchema = z.object({
  id: z.string(),
  colorRanges: colorRangesSchema,
  logoImgUrl: z.string(),
  base64LogoImg: z.string(),
  logoImgFileType: z.nativeEnum(LogoFiletype),
  compactLogoImgUrl: z.string(),
  carrierId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  navigationSidebarVariant: z.nativeEnum(NavigationSidebarVariants),
  isDefault: z.boolean(),
  userGroup: z.array(userGroupSchema).optional(),
});

export const partialThemeSchema = z.object({
  colorRanges: colorRangesSchema,
  logoImgUrl: z.string(),
  base64LogoImg: z.string(),
  logoImgFileType: z.nativeEnum(LogoFiletype),
  compactLogoImgUrl: z.string(),
  carrierId: z.string(),
  navigationSidebarVariant: z.nativeEnum(NavigationSidebarVariants),
  isDefault: z.boolean(),
  userGroup: z.array(userGroupSchema).optional(),
});

export type Theme = z.infer<typeof themeSchema>;
export type PartialTheme = z.infer<typeof partialThemeSchema>;

export enum LeadPlatformHeaderVariants {
  primary = 'primary',
  secondary = 'secondary',
  light = 'light',
}

export const themeArraySchema = z.array(themeSchema);
