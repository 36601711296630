import { z } from 'zod';

import { KeepTypeAlias } from '../utilities';

export const questionnaireVersionPricingNodeIdsSchema = z.object({
  id: z.string(),
  nodeIds: z.array(z.string()),
  questionnaireVersionId: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const questionnaireVersionPricingNodeIdArraySchema = z.array(questionnaireVersionPricingNodeIdsSchema);

export type QuestionnaireVersionPricingNodeIds = KeepTypeAlias<
  z.infer<typeof questionnaireVersionPricingNodeIdsSchema>
>;
