import { array as zodArray, string as zodString, boolean as zodBoolean, object as zodObject, TypeOf } from 'zod';

const submissionFileApiParameterSchema = zodObject({
  metadata: zodObject({
    isUrl: zodBoolean().optional(),
  }).optional(),
  name: zodString(),
  data: zodString(),
  dataType: zodString(),
  fileType: zodString(),
  encrypted: zodBoolean(),
  encryptionKey: zodString().optional(),
  salt: zodString().optional(),
});

export const ingeniumPayloadEntitySchema = zodObject({
  transactionId: zodString(),
  saleTool: zodString(),
  productCategory: zodString(),
  files: zodArray(submissionFileApiParameterSchema),
});

export type IngeniumPayloadEntity = TypeOf<typeof ingeniumPayloadEntitySchema>;
