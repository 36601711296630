import { Result, success, failure } from '@breathelife/result';

export const DEFAULT_TIMEZONE_NAME = 'America/Toronto';

export class TimezoneError extends Error {
  readonly tag = 'TimezoneError';
}

export class Timezone {
  // The private readonly __tag prevents callers for using the 'as' keyword to cast unvalidated timezone name.
  // Removing this property would be less safe.
  // @ts-ignore
  private readonly tag: 'Timezone';
  public readonly name: string;

  private constructor(name: string) {
    this.tag = 'Timezone';
    this.name = name;
  }

  static from(name: string): Result<TimezoneError, Timezone> {
    try {
      Intl.DateTimeFormat(undefined, { timeZone: name });

      return success(new Timezone(name));
    } catch (error: any) {
      return failure(new TimezoneError());
    }
  }
}

export const DEFAULT_TIMEZONE = Timezone.from(DEFAULT_TIMEZONE_NAME).unsafe();
