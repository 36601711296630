// TODO: namespace type by domain (in this case - Application) to better organize code

import { SupportedFileExtType, SupportedFileMimeType } from '../models';

import { Parser, ParsingError, failure, success } from '@breathelife/result';

// TODO: DEV-9676 validate mime types on the application files service based on this list
export const supportedApplicationFileMimeTypes = [
  SupportedFileMimeType.pdf,
  SupportedFileMimeType.jpeg,
  SupportedFileMimeType.png,
  SupportedFileMimeType.msword,
  SupportedFileMimeType.document,
];

export const supportedApplicationFileExtTypes = [
  SupportedFileExtType.pdf,
  SupportedFileExtType.jpeg,
  SupportedFileExtType.png,
  SupportedFileExtType.doc,
  SupportedFileExtType.docx,
];

export const checkSupportedApplicationFileMimeTypes: Parser<SupportedFileMimeType> = (value: unknown) => {
  if (typeof value === 'string') {
    switch (value) {
      case SupportedFileMimeType.pdf:
        return success(SupportedFileMimeType.pdf);
      case SupportedFileMimeType.jpeg:
        return success(SupportedFileMimeType.jpeg);
      case SupportedFileMimeType.png:
        return success(SupportedFileMimeType.png);
      case SupportedFileMimeType.msword:
        return success(SupportedFileMimeType.msword);
      case SupportedFileMimeType.document:
        return success(SupportedFileMimeType.document);
    }
  }
  return failure(
    new ParsingError(`Expect a SupportedApplicationFileMimeTypes enum member but got ${JSON.stringify(value)}.`),
  );
};
