import * as z from 'zod';

const localizedSchema = z.object({
  en: z.string(),
  fr: z.string(),
});

const numberRangeSchema = z.object({
  min: z.number(),
  max: z.number(),
});

const indexPageSchema = z.object({
  title: localizedSchema,
  description: localizedSchema,
  headerTags: z.string().array(),
  footerTags: z.string().array(),
});

const carrierConfigSchema = z.object({
  id: z.string(),
  companyName: localizedSchema,
  defaultLanguage: localizedSchema.keyof(),
  website: z.string().optional(),
  contactEmail: z.string().optional(),
  defaultCountryCode: z.string(),
  defaultProductId: z.string().optional(),
  productIds: z.string().array().optional(),
  defaultFaceValue: z.number().optional(),
  lastStepsIds: z.string().array().optional(),
  validApplyAgeRange: numberRangeSchema.nullable().optional(),
  validQuoteAgeRange: numberRangeSchema.nullable().optional(),
  validQuoteCoverageRange: numberRangeSchema.nullable().optional(),
  validApplyCoverageRange: numberRangeSchema.nullable().optional(),
  validFileUploadMimeTypes: z.string().array().optional(),
  indexPage: indexPageSchema,
  purchaseFlowUrl: z.string().optional(),
});

const infraConfigSchema = z.object({
  featureFlags: z.object({
    clientKey: z.string().nullable().optional(),
    environment: z.string(),
    url: z.string().nullable(),
  }),
});

const keysConfigSchema = z.object({
  auth0: z.object({
    clientID: z.string(),
    domain: z.string(),
    audience: z.string(),
    responseType: z.string(),
    scope: z.string(),
    dbConnection: z.string(),
    defaultToSsoConnection: z.boolean(),
    ssoConnectionName: z.string().optional(),
    ssoFederatedLogout: z.boolean().optional(),
  }),
  segment: z.string(),
  unsubToolUrl: z.string(),
  cloudinary: z.object({
    cloudName: z.string(),
    uploadPreset: z.string(),
    folder: z.string(),
  }),
  googleMapsPlaces: z.string(),
});

const sentryConfigSchema = z.object({
  enabled: z.boolean(),
  dsn: z.string(),
});

const sentryEventFiltersConfigSchema = z.object({
  domains: z
    .object({
      domains: z.string().array(),
      severity: z.string(),
      fingerprint: z.string(),
    })
    .array()
    .optional(),
  messages: z
    .object({
      message: z.string(),
      severity: z.string(),
      fingerprint: z.string(),
    })
    .array()
    .optional(),
});

const dataDogConfigSchema = z.object({
  enabled: z.boolean(),
  applicationId: z.string(),
  clientToken: z.string(),
  site: z.string().optional(),
  service: z.string(),
  sampleRate: z.number().optional(),
  replaySampleRate: z.number().optional(),
  trackUserInteractions: z.boolean().optional(),
  trackFrustrations: z.boolean().optional(),
});

const monitoringConfigSchema = z.object({
  environment: z.string(),
  sentry: sentryConfigSchema,
  sentryEventFilters: sentryEventFiltersConfigSchema,
  datadog: dataDogConfigSchema,
});

const uiConfigSchema = z.object({
  displayFloatingScheduleCallButton: z.object({
    desktop: z.boolean(),
    mobile: z.boolean(),
  }),
  productLoadingDuration: z.object({
    life: z.number(),
  }),
});

const gitConfigSchema = z.object({
  commitSha: z.string().optional(),
});

const frontEndConfigSchema = z.object({
  infra: infraConfigSchema,
  carrier: carrierConfigSchema,
  keys: keysConfigSchema,
  monitoring: monitoringConfigSchema,
  features: z.any(), // TODO: should we bring what we have in backend/types here?
  featureFlags: z.record(z.string(), z.boolean()),
  ui: uiConfigSchema,
  debug: z.record(z.string(), z.boolean()),
  git: gitConfigSchema,
  localization: z.any(),
});

export type FrontEndConfig = z.infer<typeof frontEndConfigSchema>;
