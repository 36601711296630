import { z } from 'zod';
import { CanadianProvince, UsState } from '../answers';
import { FinancialActivityType } from './typecodes';

export const ExtensionSchema = z.record(z.any());
export type Extension = z.infer<typeof ExtensionSchema>;

export const financialActivityType = z.nativeEnum(FinancialActivityType);

export enum RoleType {
  insured = 'insured',
  owner = 'owner',
  payer = 'payer',
}

export const roleType = z.nativeEnum(RoleType);

export enum AddressTypeCode {
  billing = 'billing',
  home = 'home',
  mailing = 'mailing',
  other = 'other',
  previous = 'previous',
  shipping = 'shipping',
  work = 'work',
}

export const addressTypeCode = z.nativeEnum(AddressTypeCode);

export enum PhoneTypeCode {
  cell = 'cell',
  home = 'home',
  work = 'work',
}

export const phoneTypeCode = z.nativeEnum(PhoneTypeCode);

export enum GenderCode {
  male = 'male',
  female = 'female',
  other = 'other',
  unknown = 'unknown',
}

export const genderCode = z.nativeEnum(GenderCode);

export enum PartyIdentityCode {
  ssn = 'SSN',
  tin = 'TIN',
  id = 'ID',
}

export const partyIdentityCode = z.nativeEnum(PartyIdentityCode);

export enum NameCode {
  dba = 'DBA',
  aka = 'AKA',
}

export const nameCode = z.nativeEnum(NameCode);

export enum BeneficiaryTypeCode {
  primary = 'primary',
  contingent = 'contingent',
}

export const beneficiaryTypeCode = z.nativeEnum(BeneficiaryTypeCode);

export enum CoverageIndicatorCode {
  base = 'base',
  rider = 'rider',
  unknown = 'unknown',
}

export const coverageIndicatorCode = z.nativeEnum(CoverageIndicatorCode);

export enum RelationshipCode {
  brother = 'brother',
  brotherInLaw = 'brotherInLaw',
  businessMgr = 'businessMgr',
  charity = 'charity',
  church = 'church',
  corporation = 'corporation',
  daughter = 'daughter',
  daughterPerStirpes = 'daughterPerStirpes',
  daughterInLaw = 'daughterInLaw',
  domesticPartner = 'domesticPartner',
  employer = 'employer',
  estate = 'estate',
  exSpouse = 'exSpouse',
  father = 'father',
  fatherInLaw = 'fatherInLaw',
  fiance = 'fiance',
  fosterChild = 'fosterChild',
  grandchild = 'grandchild',
  grandchildPerstirpe = 'grandchildPerstirpe',
  granddaughter = 'granddaughter',
  grandfather = 'grandfather',
  grandmother = 'grandmother',
  grandson = 'grandson',
  guardian = 'guardian',
  mother = 'mother',
  motherInLaw = 'motherInLaw',
  nephew = 'nephew',
  niece = 'niece',
  other = 'other',
  parent = 'parent',
  sister = 'sister',
  sisterInLaw = 'sisterInLaw',
  son = 'son',
  sonPerStirpes = 'sonPerStirpes',
  sonInLaw = 'sonInLaw',
  spouse = 'spouse',
  stepFather = 'stepFather',
  stepMother = 'stepMother',
  stepchild = 'stepchild',
  trust = 'trust',
  trustee = 'trustee',
}

export const relationshipCode = z.nativeEnum(RelationshipCode);

export enum MaritalStatusCode {
  married = 'married',
  single = 'single',
  widowed = 'widowed',
  divorced = 'divorced',
  civilUnionDomesticPartnership = 'civilUnionDomesticPartnership',
}

export const maritalStatusCode = z.nativeEnum(MaritalStatusCode);

export enum InsuredTypeCode {
  primary = 'primary',
}

export const insuredTypeCode = z.nativeEnum(InsuredTypeCode);

export enum AgentTypeCode {
  writing = 'writing',
  servicing = 'servicing',
  individual = 'individual',
}

export const agentTypeCode = z.nativeEnum(AgentTypeCode);

export enum JurisdictionCode {
  CA = 'CA',
  CO = 'CO',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  ND = 'ND',
  OH = 'OH',
  OR = 'OR',
  SD = 'SD',
  WA = 'WA',
  WI = 'WI',
}

export const jurisdictionCode = z.nativeEnum(JurisdictionCode);

export enum ProductTypeCode {
  base = 'base',
  rider = 'rider',
}

export const productTypeCode = z.nativeEnum(ProductTypeCode);

export const NameBaseSchema = z.object({
  givenName: z.string(),
  otherGivenName: z.string().optional(),
  surname: z.string(),
});
export type NameBase = z.infer<typeof NameBaseSchema>;

export const NameSchema = NameBaseSchema.extend({
  id: z.string(),
  typeCode: z.optional(nameCode),
});
export type Name = z.infer<typeof NameSchema>;

export const AddressBaseSchema = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  city: z.string(),
  stateOrProvinceCode: z.union([z.nativeEnum(CanadianProvince), z.nativeEnum(UsState)]),
  postalCode: z.string(),
});
export type AddressBase = z.infer<typeof AddressBaseSchema>;

export const CommunicationSchema = z.object({
  id: z.string(),
  phoneNumber: z.string(),
  phoneTypeCode: phoneTypeCode,
  emailAddress: z.string().optional(),
  primaryIndicator: z.boolean(),
});
export type Communication = z.infer<typeof CommunicationSchema>;

export const AddressSchema = AddressBaseSchema.extend({
  id: z.string(),
  typeCode: addressTypeCode,
});
export type Address = z.infer<typeof AddressSchema>;

export const RelationshipSchema = z.object({
  id: z.string(),
  description: z.string().optional(),
  relationshipToCode: relationshipCode.optional(),
});
export type Relationship = z.infer<typeof RelationshipSchema>;

export const CoverageSchema = z.object({
  id: z.string(),
  amount: z.number().optional(),
  indicatorCode: coverageIndicatorCode,
  productCode: z.string(),
});
export type Coverage = z.infer<typeof CoverageSchema>;

export const RiskSchema = z.object({
  id: z.string(),
  typeCode: z.string(),
});
export type Risk = z.infer<typeof RiskSchema>;

export const ProductSchema = z.object({
  id: z.string(),
  typeCode: z.string(),
  coverage: z.array(CoverageSchema),
  risk: z.optional(RiskSchema),
  extension: ExtensionSchema.optional(),
});
export type Product = z.infer<typeof ProductSchema>;

export const EmployeePay = z.object({
  id: z.string(),
  amount: z.number(),
});

export const Employee = z.object({
  id: z.string(),
  companyName: z.string(),
  employeePay: EmployeePay,
});

export const PartySchema = z.object({
  name: z.array(NameSchema).optional(),
  age: z.number().optional(),
  address: z.array(AddressSchema).optional(),
  communication: z.array(CommunicationSchema).optional(),
  genderCode: z.string().optional(),
  birthDate: z.string().optional(),
  partyIdentity: z
    .array(
      z.object({
        typeCode: partyIdentityCode,
        typeId: z.string().optional(),
        extension: ExtensionSchema.optional(),
      }),
    )
    .optional(),
  maritalStatus: z.optional(maritalStatusCode),
  extension: ExtensionSchema.optional(),
});
export type Party = z.infer<typeof PartySchema>;

export const FinancialActivitySchema = z.object({
  finActivityType: financialActivityType,
});
export type FinancialActivity = z.infer<typeof FinancialActivitySchema>;

export const PartyIdentitySchema = z.object({
  typeCode: partyIdentityCode,
  typeId: z.string(),
  extension: ExtensionSchema.optional(),
});
export type PartyIdentity = z.infer<typeof PartyIdentitySchema>;

export const InsuredSchema = PartySchema.extend({
  id: z.string(),
  typeCode: z.string(),
  occupation: z.string(),
  employee: Employee,
  extension: ExtensionSchema.optional(),
});
export type Insured = z.infer<typeof InsuredSchema>;

export const OwnerSchema = PartySchema.extend({
  id: z.string(),
  relationship: z.optional(RelationshipSchema),
  extension: ExtensionSchema.optional(),
});
export type Owner = z.infer<typeof OwnerSchema>;

export const PayerSchema = PartySchema.extend({
  id: z.string(),
  extension: ExtensionSchema.optional(),
});
export type Payer = z.infer<typeof PayerSchema>;

export const PaymentSchema = z.object({
  id: z.string(),
  frequencyCode: z.string(),
});
export type Payment = z.infer<typeof PaymentSchema>;

export const PremiumSchema = z.object({
  id: z.string(),
  amount: z.number(),
});
export type Premium = z.infer<typeof PremiumSchema>;

export const BeneficiarySchema = PartySchema.extend({
  id: z.string(),
  typeCode: beneficiaryTypeCode,
  coverageId: z.string(),
  insuredId: z.string(),
  relationship: z.optional(RelationshipSchema),
  extension: ExtensionSchema.optional(),
});
export type Beneficiary = z.infer<typeof BeneficiarySchema>;

export const AgentSchema = PartySchema.extend({
  id: z.string(),
  number: z.string().or(z.number()),
  typeCode: agentTypeCode,
  extension: ExtensionSchema.optional(),
});
export type Agent = z.infer<typeof AgentSchema>;

export const SignatureInfoSchema = z.object({
  id: z.string(),
  formState: z.string(),
  extension: ExtensionSchema.optional(),
});
export type SignatureInfo = z.infer<typeof SignatureInfoSchema>;

export const AcordJsonInputDataSchema = z.object({
  number: z.string().optional(),
  insured: z.array(InsuredSchema),
  owner: z.array(OwnerSchema),
  payer: z.array(PayerSchema),
  beneficiary: z.array(BeneficiarySchema),
  product: z.array(ProductSchema),
  agent: z.array(AgentSchema),
  premium: PremiumSchema,
  payment: PaymentSchema,
  signatureInfo: SignatureInfoSchema,
  jurisdiction: jurisdictionCode,
  financialActivity: z.optional(FinancialActivitySchema),
  extension: ExtensionSchema.optional(),
});
export type AcordJsonInputData = z.infer<typeof AcordJsonInputDataSchema>;
