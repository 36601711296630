export enum ConsumerFeedbackProvider {
  DELIGHTED = 'delighted',
}

export enum DelightedSurveys {
  CSAT = 'csat',
  NOTIFICATION_CENTRE = 'notificationCentre',
}

export type DelightedSurveysConfig = {
  [DelightedSurveys.CSAT]?: {
    name: string;
    surveyId: string;
  };
  [DelightedSurveys.NOTIFICATION_CENTRE]?: {
    link: string;
  };
};
