/* eslint-disable @typescript-eslint/no-use-before-define */
import { AgeRoundingType, Condition, DateUnit, condition } from '../carrierQuestionnaire';
import { Infer, IsTrue, t } from 'typegate';
import {
  BlueprintCollectionOperator,
  BlueprintConditionValue,
  BlueprintConditionsValue,
  BlueprintSingleConditionValue,
  ConditionBlueprintType,
  InstancesCountMethod,
  MatchesConditionPropertyQuantifier,
  MathConditionOperator,
  NumberComparisonConditionOperator,
  YesNoValue,
} from './conditionBlueprints';
import { Localizable } from '../localization/localization';

const reflexiveConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.reflexive)),
  t.property('value', t.enum(YesNoValue)),
  t.property('targetNodeId', t.string),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const ageRangeUnit = t.union(t.literal(DateUnit.month), t.literal(DateUnit.year), t.literal(DateUnit.day));

const ageRangeConditionValue = t
  .object(
    t.property(
      'value',
      t.object(t.property('minAge', t.number), t.property('maxAge', t.number), t.property('unit', ageRangeUnit)),
    ),
    t.property('targetBirthdateNodeId', t.string),
    t.property('type', t.literal(ConditionBlueprintType.ageRange)),
    t.optionalProperty('roundingType', t.enum(AgeRoundingType)),
    t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
  )
  .setTypeName('AgeRangeConditionValue');

const characterCountInBetweenConditionValue = t.object(
  t.property('value', t.object(t.optionalProperty('minLength', t.number), t.optionalProperty('maxLength', t.number))),
  t.property('targetNodeId', t.string),
  t.property('type', t.literal(ConditionBlueprintType.characterCountInBetween)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const nodeIdToCountBase = t.object(
  t.property('nodeId', t.string),
  t.optionalProperty('repeatableCollectionNodeId', t.string),
);

const addLengthOfArray = t.intersection(
  t.object(t.property('countMethod', t.literal(InstancesCountMethod.addLengthOfArray))),
  nodeIdToCountBase,
);

const addOneIfExists = t.intersection(
  t.object(t.property('countMethod', t.literal(InstancesCountMethod.addOneIfExists))),
  nodeIdToCountBase,
);

const addValue = t.intersection(
  t.object(t.property('countMethod', t.literal(InstancesCountMethod.addValue))),
  nodeIdToCountBase,
);

const addOneIfValueEquals = t.intersection(
  t.object(
    t.property('countMethod', t.literal(InstancesCountMethod.addOneIfValueEquals)),
    t.property('compareValue', t.unknown),
  ),
  nodeIdToCountBase,
);

const addNumberIfValueEquals = t.intersection(
  t.object(
    t.property('countMethod', t.literal(InstancesCountMethod.addNumberIfValueEquals)),
    t.property('numberToAdd', t.number),
    t.property('compareValue', t.unknown),
  ),
  nodeIdToCountBase,
);

const nodeIdToCount = t.union(addLengthOfArray, addOneIfExists, addValue, addOneIfValueEquals, addNumberIfValueEquals);

const instancesCountConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.instancesCount)),
  t.optionalProperty('limitOf', t.number),
  t.optionalProperty('minimumOf', t.number),
  t.property('nodeIdsToAdd', t.array(nodeIdToCount)),
  t.property('nodeIdsToSubtract', t.array(nodeIdToCount)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const jointProductAgeRangeConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.jointProductAgeRange)),
  t.property(
    'jointProductNodeIds',
    t.array(t.object(t.property('surrogateId', t.string), t.property('productTypeId', t.string))),
  ),
  t.property('insuredBirthdayNodeId', t.string),
  t.property('repeatableInsuredCollectionId', t.string),
  t.property(
    'value',
    t.object(t.property('maxAge', t.number), t.optionalProperty('minAge', t.number), t.property('unit', ageRangeUnit)),
  ),
  t.optionalProperty('roundingType', t.enum(AgeRoundingType)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const bodyMassIndexRangeConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.bmiRange)),
  t.property('value', t.object(t.property('minBMI', t.number), t.property('maxBMI', t.number))),
  t.property('heightNodeId', t.string),
  t.property('weightNodeId', t.string),
  t.property('heightUnit', t.union(t.literal('cm'), t.literal('inch'))),
  t.property('weightUnit', t.union(t.literal('lb'), t.literal('kg'))),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const mathOperatorConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.mathOperator)),
  t.optionalProperty('value', t.number),
  t.optionalProperty('nodeIdOfValue', t.string),
  t.property('nodeIds', t.array(t.string)),
  t.property('operator', t.enum(NumberComparisonConditionOperator)),
  t.property('mathOperator', t.enum(MathConditionOperator)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const equalityConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.equality)),
  t.optionalProperty('value', t.union(t.string, t.number, t.boolean)),
  t.property('isEqual', t.boolean),
  t.property('targetNodeId', t.string),
  t.optionalProperty('nodeIdOfValue', t.string),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const emptinessConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.emptiness)),
  t.property('isEmpty', t.boolean),
  t.property('targetNodeId', t.string),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const matchesConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.matches)),
  t.property('value', t.array(t.string)),
  t.property('quantifier', t.enum(MatchesConditionPropertyQuantifier)),
  t.property('targetNodeId', t.string),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const numberComparisonConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.numberComparison)),
  t.property('operator', t.enum(NumberComparisonConditionOperator)),
  t.optionalProperty('value', t.number),
  t.property('targetNodeId', t.string),
  t.optionalProperty('nodeIdOfValue', t.string),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const dateComparisonConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.dateComparison)),
  t.property('startDateNodeId', t.string),
  t.property('endDateNodeId', t.string),
  t.property('value', t.number),
  t.property('unit', t.enum(DateUnit)),
  t.property('operator', t.enum(NumberComparisonConditionOperator)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const percentOfComparisonConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.percentOf)),
  t.property('targetNodeId', t.string),
  t.property('percent', t.number),
  t.property('operator', t.enum(NumberComparisonConditionOperator)),
  t.optionalProperty('value', t.number),
  t.optionalProperty('nodeIdOfValue', t.string),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const countEqualConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.countEqual)),
  t.property('targetNodeId', t.string),
  t.property('value', t.number),
  t.property('controlValue', t.union(t.string, t.number, t.boolean)),
  t.property('operator', t.enum(NumberComparisonConditionOperator)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const lastIncidentDateConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.lastIncidentDate)),
  t.property('targetNodeId', t.string),
  t.property('value', t.number),
  t.property('unit', t.enum(DateUnit)),
  t.property('operator', t.enum(NumberComparisonConditionOperator)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const futureDateConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.futureDate)),
  t.property('targetNodeId', t.string),
  t.property('value', t.number),
  t.property('unit', t.enum(DateUnit)),
  t.property('operator', t.enum(NumberComparisonConditionOperator)),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const engineConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.engineCondition)),
  t.property(
    'condition',
    t.lazy<Condition>(() => condition),
  ),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const matchesRegexConditionValue = t.object(
  t.property('type', t.literal(ConditionBlueprintType.matchesRegex)),
  t.property('targetNodeId', t.string),
  t.property('regex', t.string),
  t.optionalProperty('collectionOperators', t.record(t.string, t.enum(BlueprintCollectionOperator))),
);

const blueprintSingleConditionValue = t.union(
  ageRangeConditionValue,
  bodyMassIndexRangeConditionValue,
  characterCountInBetweenConditionValue,
  countEqualConditionValue,
  dateComparisonConditionValue,
  emptinessConditionValue,
  engineConditionValue,
  equalityConditionValue,
  futureDateConditionValue,
  instancesCountConditionValue,
  jointProductAgeRangeConditionValue,
  lastIncidentDateConditionValue,
  matchesConditionValue,
  matchesRegexConditionValue,
  mathOperatorConditionValue,
  numberComparisonConditionValue,
  percentOfComparisonConditionValue,
  reflexiveConditionValue,
);

const lspHeavyBlueprintConditionValue = t.union(
  t.lazy<BlueprintSingleConditionValue>(() => blueprintSingleConditionValue),
  t.lazy<BlueprintConditionsValue>(() => blueprintConditionsValue),
);
export const blueprintConditionValue = t.lazy<BlueprintConditionValue>(() => lspHeavyBlueprintConditionValue);

export type FinalCheck1 = IsTrue<
  Infer<typeof lspHeavyBlueprintConditionValue> extends BlueprintConditionValue ? true : false
>;
export type FinalCheck2 = IsTrue<
  BlueprintConditionValue extends Infer<typeof lspHeavyBlueprintConditionValue> ? true : false
>;

export const blueprintConditionsValue = t.object(
  t.property('conditions', t.array(t.lazy<BlueprintConditionValue>(() => lspHeavyBlueprintConditionValue))),
);

export const blueprintConditionsValueWithMessage = t.object(
  t.optionalProperty('id', t.string),
  t.property('conditions', lspHeavyBlueprintConditionValue),
  t.property('message', t.noCheck<Partial<Localizable>>()),
);
