import { conditions, Conditions, query, Query } from './conditions';
import * as t from 'typegate';

export enum SetValueEffectSpecialValue {
  newId = '{{new_id}}',
}

export const effectValue = t.union(
  t.string,
  t.boolean,
  t.number,
  t.lazy<Query>(() => query),
  t.tsEnum(SetValueEffectSpecialValue),
  t.undefined,
);

export type EffectValue = t.Infer<typeof effectValue>;

export const setValueEffect = t
  .object(
    t.property('at', t.string),
    t.property('value', effectValue),
    t.optionalProperty(
      'triggerIf',
      t.lazy<Conditions>(() => conditions),
    ),
    t.optionalProperty('newAnswerMustMatch', t.unknown),
  )
  .setTypeName('SetValueEffect');

export type SetValueEffect = t.Infer<typeof setValueEffect>;

export const engineEffects = t
  .object(
    t.optionalProperty('beforeValueUpdate', t.array(setValueEffect)),
    t.optionalProperty('afterValueUpdate', t.array(setValueEffect)),
  )
  .setTypeName('EngineEffects');

export type EngineEffects = t.Infer<typeof engineEffects>;
