import { Permission } from '../users/permission';

export enum ApiScopes {
  PlatformConfigurationRead = Permission.PlatformConfigurationRead,
  LeadCreate = Permission.LeadCreate,
  ApplicationCreate = Permission.ApplicationCreate,
  ApplicationFileCreate = Permission.ApplicationFileCreate,
  IntegrationsHealthRead = Permission.IntegrationsHealthRead,
  CronjobRun = Permission.CronjobRun,
}
