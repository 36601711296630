// NOTE: Please update the README.md in shared/questionnaire-engine if this is modified.
export enum FieldTypes {
  input = 'input',
  textarea = 'textarea',
  radio = 'radio',
  dropdown = 'dropdown',
  checkbox = 'checkbox',
  date = 'date',
  yearMonth = 'yearMonth',
  number = 'number',
  money = 'money',
  agree = 'agree',
  checkboxGroup = 'checkboxGroup',
  phone = 'phone',
  currencyCard = 'currencyCard',
  button = 'button',
  autocomplete = 'autocomplete',
  information = 'information',
  signature = 'signature',
}
