import { DataLabel } from '@breathelife/meta-cruncher';

import { IsoCountryCode } from '../applications/isoCountryCode';
import { ApplicationMode } from '../applications/mode';
import { IconName } from '../icons';
import { Localizable } from '../localization/localization';
import { ParticipantRoles } from '../participant';
import { PdfDocumentType } from '../carrierQuestionnaire/documentType';
import { FieldLayout } from '../carrierQuestionnaire/fieldLayout';
import { FieldTypes } from '../carrierQuestionnaire/fieldTypes';
import { InsuranceModule } from '../carrierQuestionnaire/insuranceModule';
import { NumericalDataType } from '../carrierQuestionnaire/numericalDataType';
import { OptionSize } from '../carrierQuestionnaire/optionSize';
import { PlatformType } from '../carrierQuestionnaire/platformType';
import {
  FieldPartIdentifier,
  isFieldPartIdentifier,
  isQuestionPartIdentifier,
  isSectionGroupPartIdentifier,
  isSectionPartIdentifier,
  isSelectOptionPartIdentifier,
  isSubsectionPartIdentifier,
  PartIdentifier,
  QuestionPartIdentifier,
  SectionGroupPartIdentifier,
  SectionPartIdentifier,
  SelectOptionPartIdentifier,
  SubsectionPartIdentifier,
} from './blueprintIdentifiers';
import { BlueprintConditionsValueWithMessage, BlueprintConditionValue } from './conditionBlueprints';
import {
  DynamicOptionsBlueprint,
  FieldBlueprint,
  InformationFieldBlueprintVariant,
  InfoSupplementBlueprint,
  QuestionBlueprint,
  QuestionnaireBlueprintCopyableOption,
  QuestionnaireBlueprintRenderOn,
  SectionBlueprint,
  SectionGroupBlueprint,
  SectionGroupKey,
  SelectOptionBlueprint,
  SelectOptionsApplicationContextBlueprint,
  SubsectionBlueprint,
} from './questionnaireBlueprints';

export type BlueprintModification =
  | BlueprintCreation
  | BlueprintUpdate
  | BlueprintRemoval
  | BlueprintReorder
  | BlueprintReplacement;

export type BlueprintReplacement = FieldBlueprintReplacement;

export type FieldBlueprintReplacement = {
  partIdentifier: FieldPartIdentifier;
  replacement: FieldBlueprint;
};
export type BlueprintCreation =
  | SectionBlueprintCreation
  | SubsectionBlueprintCreation
  | QuestionBlueprintCreation
  | FieldBlueprintCreation
  | SelectOptionBlueprintCreation;

export type SectionBlueprintCreation = GenericBlueprintCreation<null, SectionBlueprint>;
export type SubsectionBlueprintCreation = GenericBlueprintCreation<SectionPartIdentifier, SubsectionBlueprint>;
export type QuestionBlueprintCreation = GenericBlueprintCreation<SubsectionPartIdentifier, QuestionBlueprint>;
export type FieldBlueprintCreation = GenericBlueprintCreation<QuestionPartIdentifier, FieldBlueprint>;
export type SelectOptionBlueprintCreation = GenericBlueprintCreation<FieldPartIdentifier, SelectOptionBlueprint>;

type GenericBlueprintCreation<TContainerIdentifier extends PartIdentifier | null, TBlueprint> = {
  partIdentifier: TContainerIdentifier;
  create: { blueprint: TBlueprint };
};

export type BlueprintUpdate =
  | SectionGroupBlueprintUpdate
  | SectionBlueprintUpdate
  | SubsectionBlueprintUpdate
  | QuestionBlueprintUpdate
  | FieldBlueprintUpdate
  | SelectOptionBlueprintUpdate;

export type SectionGroupBlueprintUpdate = {
  partIdentifier: SectionGroupPartIdentifier;
  sectionGroupKey: SectionGroupKey;
  update: SectionGroupBlueprint;
};

export type SectionBlueprintUpdate = {
  partIdentifier: SectionPartIdentifier;
  update:
    | {
        property: 'text' | 'title';
        value: Partial<Localizable>;
      }
    | { property: 'visible'; value: BlueprintConditionValue | undefined }
    | { property: 'pdfDocuments'; value: PdfDocumentType[] }
    | { property: 'modules'; value: InsuranceModule[] }
    | { property: 'dataLabel'; value: DataLabel | undefined }
    | { property: 'renderOn'; value: QuestionnaireBlueprintRenderOn[] }
    | { property: 'platforms'; value: PlatformType[] }
    | { property: 'hidden'; value: boolean }
    | { property: 'iconName'; value: IconName | undefined }
    | { property: 'copyable'; value: QuestionnaireBlueprintCopyableOption | undefined }
    | {
        property: 'referenceLabel';
        value: string;
        blueprintId: string;
        nodeId?: string;
      };
};

export type SubsectionBlueprintUpdate = {
  partIdentifier: SubsectionPartIdentifier;
  update:
    | {
        property: 'text' | 'title' | 'nextStepButtonText';
        value: Partial<Localizable>;
      }
    | { property: 'visible'; value: BlueprintConditionValue | undefined }
    | { property: 'dataLabel'; value: DataLabel | undefined }
    | { property: 'renderOn'; value: QuestionnaireBlueprintRenderOn[] }
    | { property: 'platforms'; value: PlatformType[] }
    | { property: 'hidden'; value: boolean }
    | { property: 'iconName'; value: IconName | undefined }
    | { property: 'showInNavigation'; value: boolean }
    | { property: 'copyable'; value: QuestionnaireBlueprintCopyableOption | undefined }
    | { property: 'pageBreakSubSectionInPdf'; value: boolean }
    | {
        property: 'referenceLabel';
        value: string;
        blueprintId: string;
        nodeId?: string;
      };
};

export type QuestionBlueprintUpdate = {
  partIdentifier: QuestionPartIdentifier;
  update:
    | {
        property: 'text' | 'title';
        value: Partial<Localizable>;
      }
    | { property: 'visible'; value: BlueprintConditionValue | undefined }
    | { property: 'dataLabel'; value: DataLabel | undefined }
    | { property: 'renderOn'; value: QuestionnaireBlueprintRenderOn[] }
    | { property: 'platforms'; value: PlatformType[] }
    | { property: 'hidden'; value: boolean }
    | { property: 'displayAsCard'; value: boolean }
    | { property: 'copyable'; value: QuestionnaireBlueprintCopyableOption | undefined }
    | {
        property: 'referenceLabel';
        value: string;
        blueprintId: string;
        nodeId?: string;
      }
    | {
        property: 'repeatable';
        value: QuestionBlueprint['repeatable'];
      };
};

export type FieldBlueprintUpdate = {
  partIdentifier: FieldPartIdentifier;
  update:
    | {
        property: 'text' | 'title' | 'modalHeader' | 'modalText' | 'buttonText' | 'placeholder';
        value: Partial<Localizable>;
      }
    | { property: 'visible'; value: BlueprintConditionValue | undefined }
    | { property: 'valid'; value: BlueprintConditionsValueWithMessage[] | undefined }
    | { property: 'infoSupplement'; value: InfoSupplementBlueprint | undefined }
    | { property: 'answerNodeId'; value: string }
    | {
        property:
          | 'addressAutocompleteFields.streetAddress'
          | 'addressAutocompleteFields.city'
          | 'addressAutocompleteFields.stateOrProvince'
          | 'addressAutocompleteFields.postalCodeOrZip'
          | 'addressAutocompleteNodeId';
        value: string | undefined;
      }
    | { property: 'optional'; value: boolean }
    | { property: 'fieldType'; value: FieldTypes }
    | { property: 'validateAs'; value: string }
    | { property: 'optionSize'; value: OptionSize | undefined }
    | { property: 'dataLabel'; value: DataLabel | undefined }
    | { property: 'renderOn'; value: QuestionnaireBlueprintRenderOn[] }
    | { property: 'platforms'; value: PlatformType[] }
    | { property: 'hidden'; value: boolean }
    | { property: 'variant'; value: InformationFieldBlueprintVariant }
    | { property: 'numericalDataType'; value: NumericalDataType | undefined }
    | { property: 'iconName'; value: IconName | undefined }
    | { property: 'triggerStepNavigation'; value: boolean }
    | { property: 'disabled'; value: boolean }
    | { property: 'layout'; value: FieldLayout }
    | { property: 'countryCode'; value: IsoCountryCode }
    | { property: 'defaultValue'; value: boolean | string | undefined | number | string[] }
    | { property: 'displayInCardPreview'; value: boolean }
    | { property: 'dynamicOptions'; value: DynamicOptionsBlueprint | undefined }
    | { property: 'participantRole'; value: ParticipantRoles }
    | { property: 'applicationModes'; value: ApplicationMode[] }
    | { property: 'copyable'; value: QuestionnaireBlueprintCopyableOption | undefined }
    | {
        property: 'referenceLabel';
        value: string;
        blueprintId: string;
        nodeId: string;
      }
    | {
        property: 'selectOptions';
        value: SelectOptionBlueprint[] | undefined;
      }
    | {
        property: 'selectOptionsApplicationContext';
        value: SelectOptionsApplicationContextBlueprint | undefined;
      }
    | { property: 'searchable'; value: boolean };
};

export type SelectOptionBlueprintUpdate = {
  partIdentifier: SelectOptionPartIdentifier;
  update:
    | {
        property: 'text';
        value: Partial<Localizable>;
      }
    | { property: 'hidden'; value: boolean }
    | { property: 'visible'; value: BlueprintConditionValue | undefined }
    | { property: 'partName'; value: string | undefined }
    | { property: 'orderingIndex'; value: number | undefined };
};

export type BlueprintRemoval = { partIdentifier: PartIdentifier; remove: true };

export type BlueprintReorder =
  | SectionBlueprintReorder
  | SubsectionBlueprintReorder
  | QuestionBlueprintReorder
  | FieldBlueprintReorder
  | SelectOptionBlueprintReorder;

export type SectionBlueprintReorder = GenericBlueprintReorder<SectionPartIdentifier, SectionGroupPartIdentifier>;
export type SubsectionBlueprintReorder = GenericBlueprintReorder<SubsectionPartIdentifier, SectionPartIdentifier>;
export type QuestionBlueprintReorder = GenericBlueprintReorder<QuestionPartIdentifier, SubsectionPartIdentifier>;
export type FieldBlueprintReorder = GenericBlueprintReorder<FieldPartIdentifier, QuestionPartIdentifier>;
export type SelectOptionBlueprintReorder = GenericBlueprintReorder<SelectOptionPartIdentifier, FieldPartIdentifier>;

type GenericBlueprintReorder<TIdentifier extends PartIdentifier, TParentIdentifier extends PartIdentifier = never> = {
  partIdentifier: TIdentifier;
  reorder: {
    parentIdentifier?: TParentIdentifier;
    newPreviousSiblingIdentifier?: TIdentifier;
  };
};

export function isBlueprintCreation(modification: BlueprintModification): modification is BlueprintCreation {
  return !!(modification as BlueprintCreation).create;
}

export function isBlueprintReplacement(modification: BlueprintModification): modification is BlueprintReplacement {
  return !!(modification as BlueprintReplacement).replacement;
}

export function isBlueprintUpdate(modification: BlueprintModification): modification is BlueprintUpdate {
  return !!(modification as BlueprintUpdate).update;
}

export function isBlueprintRemoval(modification: BlueprintModification): modification is BlueprintRemoval {
  return !!(modification as BlueprintRemoval).remove;
}

export function isBlueprintReorder(modification: BlueprintModification): modification is BlueprintReorder {
  return !!(modification as BlueprintReorder).reorder;
}

export function isSectionBlueprintCreation(
  blueprintCreation: BlueprintCreation,
): blueprintCreation is SectionBlueprintCreation {
  // Don't need to specify a parent item when creating a section.
  return blueprintCreation.partIdentifier === null;
}

export function isSubsectionBlueprintCreation(
  blueprintCreation: BlueprintCreation,
): blueprintCreation is SubsectionBlueprintCreation {
  // Container elements are specified when creating a sub-item.
  return isSectionPartIdentifier(blueprintCreation.partIdentifier);
}

export function isQuestionBlueprintCreation(
  blueprintCreation: BlueprintCreation,
): blueprintCreation is QuestionBlueprintCreation {
  // Container elements are specified when creating a sub-item.
  return isSubsectionPartIdentifier(blueprintCreation.partIdentifier);
}

export function isFieldBlueprintCreation(
  blueprintCreation: BlueprintCreation,
): blueprintCreation is FieldBlueprintCreation {
  // Container elements are specified when creating a sub-item.
  return isQuestionPartIdentifier(blueprintCreation.partIdentifier);
}

export function isSelectOptionBlueprintCreation(
  blueprintCreation: BlueprintCreation,
): blueprintCreation is SelectOptionBlueprintCreation {
  // Container elements are specified when creating a sub-item.
  return isFieldPartIdentifier(blueprintCreation.partIdentifier);
}

export function isSectionGroupBlueprintUpdate(
  blueprintUpdate: BlueprintUpdate,
): blueprintUpdate is SectionGroupBlueprintUpdate {
  return isSectionGroupPartIdentifier(blueprintUpdate.partIdentifier);
}

export function isSectionBlueprintUpdate(blueprintUpdate: BlueprintUpdate): blueprintUpdate is SectionBlueprintUpdate {
  return isSectionPartIdentifier(blueprintUpdate.partIdentifier);
}

export function isSubsectionBlueprintUpdate(
  blueprintUpdate: BlueprintUpdate,
): blueprintUpdate is SubsectionBlueprintUpdate {
  return isSubsectionPartIdentifier(blueprintUpdate.partIdentifier);
}

export function isQuestionBlueprintUpdate(
  blueprintUpdate: BlueprintUpdate,
): blueprintUpdate is QuestionBlueprintUpdate {
  return isQuestionPartIdentifier(blueprintUpdate.partIdentifier);
}

export function isFieldBlueprintUpdate(blueprintUpdate: BlueprintUpdate): blueprintUpdate is FieldBlueprintUpdate {
  return isFieldPartIdentifier(blueprintUpdate.partIdentifier);
}

export function isSelectOptionBlueprintUpdate(
  blueprintUpdate: BlueprintUpdate,
): blueprintUpdate is SelectOptionBlueprintUpdate {
  return isSelectOptionPartIdentifier(blueprintUpdate.partIdentifier);
}

export function isSectionBlueprintReorder(
  blueprintReorder: BlueprintReorder,
): blueprintReorder is SectionBlueprintReorder {
  return isSectionPartIdentifier(blueprintReorder.partIdentifier);
}

export function isSubsectionBlueprintReorder(
  blueprintReorder: BlueprintReorder,
): blueprintReorder is SubsectionBlueprintReorder {
  return isSubsectionPartIdentifier(blueprintReorder.partIdentifier);
}

export function isQuestionBlueprintReorder(
  blueprintReorder: BlueprintReorder,
): blueprintReorder is QuestionBlueprintReorder {
  return isQuestionPartIdentifier(blueprintReorder.partIdentifier);
}

export function isFieldBlueprintReorder(blueprintReorder: BlueprintReorder): blueprintReorder is FieldBlueprintReorder {
  return isFieldPartIdentifier(blueprintReorder.partIdentifier);
}

export function isSelectOptionBlueprintReorder(
  blueprintReorder: BlueprintReorder,
): blueprintReorder is SelectOptionBlueprintReorder {
  return isSelectOptionPartIdentifier(blueprintReorder.partIdentifier);
}
