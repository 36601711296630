import * as t from 'typegate';
import { SubsectionVariant } from '../carrierQuestionnaire/subsectionVariant';
// TODO: Add other `SubsectionVariant`s here. Some variants may have additional parameters (not just `type`).
const summarySubsectionBlueprintVariant = t.object(t.property('type', t.literal(SubsectionVariant.summary)));
export type SummarySubsectionBlueprintVariant = t.Infer<typeof summarySubsectionBlueprintVariant>;

const submissionSubsectionBlueprintVariant = t.object(
  t.property('type', t.literal(SubsectionVariant.submission)),
  t.property('displayInline', t.boolean),
  t.property('hideHeader', t.boolean),
);
export type SubmissionSubsectionBlueprintVariant = t.Infer<typeof submissionSubsectionBlueprintVariant>;

const addonSubsectionBlueprintVariant = t.object(t.property('type', t.literal(SubsectionVariant.addon)));
export type AddonSubsectionBlueprintVariant = t.Infer<typeof addonSubsectionBlueprintVariant>;

export const quoterSubsectionBlueprintVariant = t.object(
  t.property('type', t.literal(SubsectionVariant.quoter)),
  t.property('productNodeIds', t.object(t.property('product', t.string), t.property('coverageAmount', t.string))),
  t.optionalProperty('simpleQuoter', t.object(t.property('useNeedsAnalysisNodeId', t.string))),
);
export type QuoterSubsectionBlueprintVariant = t.Infer<typeof quoterSubsectionBlueprintVariant>;

const paymentSubsectionBlueprintVariant = t.object(t.property('type', t.literal(SubsectionVariant.payment)));
export type PaymentSubsectionBlueprintVariant = t.Infer<typeof paymentSubsectionBlueprintVariant>;

const productSummarySubsectionBlueprintVariant = t.object(
  t.property('type', t.literal(SubsectionVariant.productSummary)),
);
export type ProductSummarySubsectionBlueprintVariant = t.Infer<typeof productSummarySubsectionBlueprintVariant>;

const productSelectionSubsectionBlueprintVariant = t.object(
  t.property('type', t.literal(SubsectionVariant.productSelection)),
);
export type ProductSelectionSubsectionBlueprintVariant = t.Infer<typeof productSelectionSubsectionBlueprintVariant>;

export const subsectionVariantBlueprint = t.union(
  summarySubsectionBlueprintVariant,
  quoterSubsectionBlueprintVariant,
  paymentSubsectionBlueprintVariant,
  submissionSubsectionBlueprintVariant,
  addonSubsectionBlueprintVariant,
  productSelectionSubsectionBlueprintVariant,
  productSummarySubsectionBlueprintVariant,
);
export type SubsectionVariantBlueprint = t.Infer<typeof subsectionVariantBlueprint>;
