import { z } from 'zod';
import { KeepTypeAlias } from '../utilities';

const paymentTransactionSchema = z.object({
  clover: z
    .object({
      date: z.preprocess((arg) => (typeof arg == 'string' ? new Date(arg) : undefined), z.date()),
      data: z.object({
        paymentStatus: z.string(),
        transactionId: z.string(),
      }),
    })
    .optional(),
  stripe: z
    .object({
      date: z.preprocess((arg) => (typeof arg == 'string' ? new Date(arg) : undefined), z.date()),
      data: z.object({
        setupIntentId: z.string(),
        subscriptionId: z.string().optional(),
        paymentTransactionId: z.string().optional(),
      }),
    })
    .optional(),
});

export type DeletedApplicationPaymentTransaction = KeepTypeAlias<z.infer<typeof paymentTransactionSchema>>;

export const deletedApplicationSchema = z.object({
  id: z.string().uuid(),
  applicationId: z.string().uuid(),
  applicationRefNo: z.string(),
  createdDate: z.date().optional().nullable(),
  deletedDate: z.date().optional().nullable(),
  submissionInitiatedDate: z.date().optional().nullable(),
  carrierSubmissionStatus: z.string().optional().nullable(),
  userId: z.string().uuid().optional().nullable(),
  paymentTransactions: paymentTransactionSchema.optional(),
  outcome: z.record(z.object({ outcome: z.string() })).optional(),
  latestCeremonyStatus: z.string().optional().nullable(),
});

export type DeletedApplication = KeepTypeAlias<z.infer<typeof deletedApplicationSchema>>;
