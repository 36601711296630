import { z } from 'zod';

export type CreateRequest<T, TAdditionalOmissions extends string = ''> = Omit<
  T,
  'id' | 'createdAt' | 'updatedAt' | 'carrierId' | TAdditionalOmissions
>;
export type UpdateRequest<T, TAdditionalOmissions extends string = ''> = Omit<
  T,
  'createdAt' | 'updatedAt' | 'carrierId' | TAdditionalOmissions
>;

export type SortDirection = 'asc' | 'desc';

export type SortParams<T> = { field: keyof T; direction: SortDirection };

export type PageQueryOptions<T, U = string, V = string> = {
  $limit?: number;
  $query?: string;
  $page?: number;
  $sort?: SortParams<T>;
  $search?: string;
  $filter?: U;
  $searchMode?: V;
};

type IsValidFields = (fields: string) => boolean;

export const createFindQueryParams = (isValidFields: IsValidFields) =>
  z.object({
    $limit: z.string(),
    $page: z.string(),
    $search: z.string().optional(),
    $searchMode: z.string().optional(),
    $fields: z.string().refine(isValidFields).optional(),
  });
