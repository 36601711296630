import * as z from 'zod';
import { KeepTypeAlias } from '../utilities';
import { localizableSchema } from '../localization/localization';

// NOTE: These values are sometimes used directly in MIB calls.
export enum LineOfBusinessName {
  life = 'life',
  annuity = 'annuity',
  disability = 'disability',
  health = 'health',
  longTermCare = 'longTermCare',
  criticalIllness = 'criticalIllness',
  medicalSupplement = 'medicalSupplement',
  accidentIllness = 'accidentIllness',
  incomeProtection = 'incomeProtection',
  legacyLife = 'legacyLife',
  legacyAnnuity = 'legacyAnnuity',
}

export const lineOfBusinessTagSchema = z.nativeEnum(LineOfBusinessName);

export const lineOfBusinessSchema = z.object({
  id: z.string().uuid(),
  name: localizableSchema,
  tag: lineOfBusinessTagSchema,
});

export const lineOfBusinessArraySchema = z.array(lineOfBusinessSchema);

export type LineOfBusiness = KeepTypeAlias<z.infer<typeof lineOfBusinessSchema>>;
