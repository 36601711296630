import * as z from 'zod';
import { KeepTypeAlias } from '../utilities';

export const eSignAdhocStoredFileModel = z.object({
  id: z.string(),
  adhocCeremonyId: z.string(),
  storageKey: z.string(),
  storageBucket: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ESignAdhocStoredFileModel = KeepTypeAlias<z.infer<typeof eSignAdhocStoredFileModel>>;
