import { FileTemplateRecipient } from '../esign/eSignSetting';
import { Language } from '../localization';
import { StoredFileDocType } from './storedFile';

export enum FileTemplateOrigin {
  /** The file template is uploaded to our GCS bucket. */
  GCPBucket = 'GCPBucket',
  /** The file template is hosted outside our domain. */
  ExternalStatic = 'ExternalStatic',
  /** The file is generated by the PDF generator (currently unused) */
  BLGenerated = 'BLGenerated',
  /** The file is generated by the carrier's API (aka API-provided) */
  CarrierGenerated = 'CarrierGenerated',
  /** The file is provided by the user (no download link) */
  UserProvided = 'UserProvided',
}

export enum DocumentVariant {
  ModifiedEndowmentContract = 'ModifiedEndowmentContract',
}

export type FileTemplate = {
  id: string;
  name: string;
  identifier: string | null;
  docType: StoredFileDocType;
  origin: FileTemplateOrigin;
  variant: DocumentVariant | 'none' | null;
  storageKey: string | null;
  storageBucket: string | null;
  externalUrl: string | null;
  lastUsedAt: string | null;
  isRequired: boolean;
  archived: boolean;
  localizedName: { [Language.en]: string; [Language.fr]: string };
  createdAt: string;
  updatedAt: string;
};

export type ExpandableFileTemplate = FileTemplate & {
  fileTemplateRecipients?: FileTemplateRecipient[];
};
