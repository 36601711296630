export type DataExtract = {
  id: string;
  userId: string;
  storedFileId: string | undefined;
  startedAt: Date;
  completedAt: Date | undefined;
  status: DataExtractStatus;
  type: DataExtractType;
  createdAt: Date;
  updatedAt: Date;
};

export enum DataExtractStatus {
  success = 'success',
  error = 'error',
  queued = 'queued',
  inProgress = 'inProgress',
}

export enum DataExtractType {
  marketingDataExtract = 'marketingDataExtract',
}
