export enum ApplicationType {
  planFinder = 'planFinder',
  transactionFlow = 'transactionFlow',
}

export enum LaunchApplicationType {
  coBrowsing = 'co-browsing',
  redirectToCarrier = 'redirect to carrier',
  assistedApplication = 'assisted application',
}
