export type NotificationEnabled = boolean | null;

export enum EmailNotificationTypes {
  leadScheduledACallEmail = 'leadScheduledACallEmail',
  leadCompletedNeedsAnalysisEmail = 'leadCompletedNeedsAnalysisEmail',
  signatureEmailBouncedErrorEmail = 'signatureEmailBouncedErrorEmail',
  signatureCreatePackageErrorEmail = 'signatureCreatePackageErrorEmail',
  applicationSubmittedEmail = 'applicationSubmittedEmail',
}

export type UserNotificationOptions = Partial<{
  [key in EmailNotificationTypes]: NotificationEnabled;
}>;

export type UserNotificationPreference = {
  userId: string;
  createdAt?: string;
  updatedAt?: string;
} & UserNotificationOptions;
