// NOTE: Please update the README.md in shared/questionnaire-engine if this is modified.
export enum SubsectionVariant {
  form = 'form',
  summary = 'summary',
  quoter = 'quoter',
  addon = 'addon',
  blocking = 'blocking',
  submission = 'submission',
  payment = 'payment',
  productSelection = 'productSelection',
  productSummary = 'productSummary',
  priceUpdateWarning = 'priceUpdateWarning',
}
