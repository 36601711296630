import * as z from 'zod';

import { PdfDocumentType } from '../carrierQuestionnaire';
import { KeepTypeAlias } from '../utilities';

enum Type {
  person = 'PERSON',
  organization = 'ORGANIZATION',
}

const partyType = z.nativeEnum(Type);

const personSchema = z.object({
  partyId: z.string(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  middleName: z.string().optional(),
  dob: z.date().optional(),
  citizenship: z.string().optional(),
  gender: z.string().optional(),
  ethnicity: z.string().optional(),
  maritalStatus: z.string().optional(),
  deathDate: z.date().optional(),
  suffix: z.string().optional(),
  titlePrefix: z.string().optional(),
  professionalDesignation: z.string().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

type Person = z.infer<typeof personSchema>;

const addressSchema = z
  .object({
    id: z.string(),
    partyId: z.string(),
    type: z.string(),
    stateOrProvince: z.string(),
    city: z.string(),
    postalCode: z.string(),
    line1: z.string(),
    line2: z.string(),
    country: z.string(),
    createdAt: z.date(),
    updatedAt: z.date(),
  })
  .partial();

type Address = z.infer<typeof addressSchema>;

const communicationSchema = z
  .object({
    id: z.string(),
    partyId: z.string(),
    type: z.string(),
    phoneNumber: z.string(),
    phoneType: z.string(),
    email: z.string(),
    socialMediaId: z.string(),
    socialMediaType: z.string(),
    websiteUrl: z.string(),
    doNotContact: z.boolean(),
    isPrimary: z.boolean(),
    createdAt: z.date(),
    updatedAt: z.date(),
  })
  .partial();

type Communication = z.infer<typeof communicationSchema>;

enum OrganizationType {
  trust = 'TRUST',
  corporation = 'CORPORATION',
  soleProprietorship = 'SOLE_PROPRIETORSHIP',
  other = 'OTHER',
  entity = 'ENTITY',
}

const organizationType = z.nativeEnum(OrganizationType);

const organizationSchema = z.object({
  partyId: z.string().optional(),
  orgType: organizationType.optional(),
  legalEntityName: z.string(),
  legalEntityOwnerName: z.string(),
  status: z.string().optional(),
  dissolutionName: z.string().optional(),
  dissolutionDate: z.date().optional(),
  foundationDate: z.date().optional(),
  totalRevenue: z.string(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

type Organization = z.infer<typeof organizationSchema>;

const partySchema = z.object({
  id: z.string(),
  type: partyType,
  communicationPreference: z.string().optional(),
  legalEntityType: z.string().optional(),
  language: z.string().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
  address: addressSchema.nullable().optional(),
  communication: communicationSchema.nullable().optional(),
  person: personSchema.partial().nullable(),
  organization: organizationSchema.partial().nullable(),
});

type Party = z.infer<typeof partySchema>;

const createOmits = {
  person: true,
  organization: true,
} as const;

const flattenedPartySchema = partySchema
  .omit(createOmits)
  .and(personSchema.partial())
  .and(organizationSchema.partial());
export type Flattened = z.infer<typeof flattenedPartySchema>;

const signerSchema = z.object({
  partyId: z.string(),
  party: partySchema,
  roles: z
    .object({
      type: z.string(),
      position: z.number(),
    })
    .array(),
});

const signersPerDocumentTypeSchema = z.record(z.nativeEnum(PdfDocumentType), signerSchema.array());

type SignersPerDocumentType = KeepTypeAlias<z.infer<typeof signersPerDocumentTypeSchema>>;

export {
  partySchema,
  signersPerDocumentTypeSchema,
  Party,
  Organization,
  OrganizationType,
  Communication,
  Address,
  Person,
  Type,
  Type as PartyType,
  SignersPerDocumentType,
};
