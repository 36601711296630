import { Permission } from '../users/permission';

export enum LeadStatusEnum {
  new = 'new',
  invited = 'invited',
  planFinderStarted = 'planFinderStarted',
  planFinderCompleted = 'planFinderCompleted',
  engaged = 'engaged',
  transactionFlowStarted = 'transactionFlowStarted',
  transactionFlowCompleted = 'transactionFlowCompleted',
  qualified = 'qualified',
  submissionStarted = 'submissionStarted',
  submissionManuallyCompleted = 'submissionManuallyCompleted',
  submissionCompleted = 'submissionCompleted',
}

export type LeadStatus = keyof typeof LeadStatusEnum;

export enum LeadStatusesKeys {
  new = 'new',
  invited = 'invited',
  started = 'started',
  toContact = 'toContact',

  progressing = 'progressing',
  finalized = 'finalized',

  questionnaireStarted = 'questionnaireStarted',

  analysisStarted = 'analysisStarted',
  analysisComplete = 'analysisComplete',

  applicationInProgress = 'applicationInProgress',
  applicationStarted = 'applicationStarted',
  applicationCompleted = 'applicationCompleted',
  applicationSubmitted = 'applicationSubmitted',

  submissionInProgress = 'submissionInProgress',
}

export enum LeadDetailTab {
  personalInfo = 'personalInfo',
  needsAnalysis = 'needsAnalysis',
  notes = 'notes',
  history = 'history',
  applications = 'applications',
}

export enum LeadTableColumnKeys {
  actions = 'actions',
  applicationMode = 'applicationMode',
  applicationRefNo = 'applicationRefNo',
  assignedTo = 'assignedTo',
  bestTimeToReach = 'bestTimeToReach',
  clientName = 'clientName',
  connected = 'connected',
  contacted = 'contacted',
  creationDate = 'creationDate',
  email = 'email',
  lineOfBusiness = 'lineOfBusiness',
  ownerDetails = 'ownerDetails',
  progress = 'progress',
  proposedInsuredAndAnnuitant = 'proposedInsuredAndAnnuitant',
  phoneNumber = 'phoneNumber',
  refNoIncrement = 'refNoIncrement',
  referralCode = 'referralCode',
  signatureStatus = 'signatureStatus',
  status = 'status',
  updatedAt = 'updatedAt',
}

export enum LeadsTableColumnName {
  applicationMode = 'applicationMode',
  assignedToName = 'assignedToName',
  communication = 'leads-communication',
  contacted = 'contacted',
  createdAt = 'createdAt',
  email = 'email',
  fullName = 'fullName',
  lineOfBusiness = 'lineOfBusiness',
  ownerDetails = 'ownerDetails',
  phoneNumber = 'phoneNumber',
  progress = 'progress',
  refNo = 'refNo',
  refNoIncrement = 'refNoIncrement',
  referralCode = 'referralCode',
  signatureStatus = 'signatureStatus',
  status = 'status',
  updatedAt = 'updatedAt',
}

export type LeadTableColumnInfo = {
  name: LeadsTableColumnName;
  title?: LeadTableColumnKeys;
  permission?: Permission;
  defaultSort?: 'asc' | 'desc';
};

export type Lead = {
  id?: number;
  name?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string | null;
  bestMomentToCall?: string;
  /** @deprecated Please use `leadId` in `Application` instead */
  applicationId?: string;
  applicationRefNo?: string;
  productLine: string;
  contacted: boolean;
  age?: number;
  gender?: string;
  smoker?: string;
  language?: string; // Copy of the application's language (only used in BNC).
  product?: string;
  faceValue?: number;
  assignedToId?: string | null;
  state?: string;
  status?: LeadStatus;
  city?: string;
  email?: string;
  archived: boolean;
  submittedByUser: boolean;
  leadType?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  isContactable: boolean;
  postalCode?: string;
  region?: string;
  communicationLanguage: string;
  referralCode?: string;
  //TODO: remove this once there are no more carrier on the legacy leads platform
  assignedToName?: string | null;
  marketingMetadata?: string;
  isPreview: boolean;
  ownerDetails?: string | null;
};
