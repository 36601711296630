/* eslint-disable @typescript-eslint/no-use-before-define */
import { BooleanOperator, CollectionOperator, ComparisonOperator } from './operators';
import { QueryOperator, QueryOperatorParameters, queryOperatorParameters } from './queryOperators';
import { t, Infer } from 'typegate';

export type Query = {
  select: string[];
  selectWithConditions?: SelectWithConditions[];
  selectRepeatable?: RepeatableSelection[];
  operator: QueryOperator;
  subQuery?: Query;
  operatorParams?: QueryOperatorParameters;
};

export const conditionBase = t.object(
  t.property('operator', t.enum(ComparisonOperator)),
  t.optionalProperty('value', t.unknown),
  t.optionalProperty('controlValue', t.string),
  t.optionalProperty(
    'controlValueQuery',
    t.lazy<Query>(() => query),
  ),
  t.optionalProperty('nodeId', t.string),
);
export type ConditionBase = Infer<typeof conditionBase>;

const conditionSingleField = t.intersection(conditionBase, t.object(t.property('nodeId', t.string)));
export type ConditionSingleField = Infer<typeof conditionSingleField>;

export type ConditionValidationData = {
  dateMax?: string;
  dateMin?: string;
};

const conditionCollection = t.intersection(
  conditionBase,
  t.object(
    t.property('collection', t.string),
    t.property('collectionOperator', t.enum(CollectionOperator)),
    t.property('conditions', t.array(t.lazy<Condition>(() => condition))),
  ),
);
export type ConditionCollection = ConditionBase & {
  collection: string;
  collectionOperator: CollectionOperator;
  conditions: Condition[];
};

export const conditions = t.object(
  t.property('conditions', t.array(t.lazy<Condition>(() => condition))),
  t.optionalProperty('operator', t.enum(BooleanOperator)),
);
export type Conditions = {
  conditions: Condition[];
  operator?: BooleanOperator;
};

const selectWithConditions = t.object(
  t.property('nodeId', t.string),
  t.property('selectIf', conditions),
  t.optionalProperty('defaultValue', t.unknown),
);
export type SelectWithConditions = Infer<typeof selectWithConditions>;

export const repeatableSelection = t.union(
  t.object(
    t.property('fromCollection', t.string),
    t.property('select', t.string),
    t.optionalProperty('selectWithConditions', selectWithConditions),
  ),
  t.object(
    t.property('fromCollection', t.string),
    t.optionalProperty('select', t.string),
    t.property('selectWithConditions', selectWithConditions),
  ),
);
export type RepeatableSelection = Infer<typeof repeatableSelection>;

export const query = t.object(
  t.property('select', t.array(t.string)),
  t.optionalProperty('selectWithConditions', t.array(selectWithConditions)),
  t.optionalProperty('selectRepeatable', t.array(repeatableSelection)),
  t.property('operator', t.enum(QueryOperator)),
  t.optionalProperty(
    'subQuery',
    t.lazy<Query>(() => query),
  ),
  t.optionalProperty('operatorParams', queryOperatorParameters),
);

const conditionQuery = t.intersection(conditionBase, t.object(t.property('query', query)));
export type ConditionQuery = ConditionBase & {
  query: Query;
};

export const condition = t.union(conditionBase, conditionSingleField, conditionQuery, conditions, conditionCollection);
export type Condition = ConditionBase | ConditionSingleField | ConditionQuery | ConditionCollection | Conditions;
