import * as z from 'zod';
import { KeepTypeAlias } from '../utilities';
import { ESignAdhocStatus } from './eSignAdhoc';
import { eSignAdhocStoredFileModel } from './eSignAdhocStoredFile';

export const eSignAdhocCeremonyModel = z.object({
  id: z.string(),
  context: z.string(),
  applicationId: z.string(),
  timezone: z.string(),
  language: z.string(),
  onComplete: z.unknown(),
  status: z.nativeEnum(ESignAdhocStatus),
  email: z.unknown(),
  documentsToSign: z.unknown(),
  transactions: z.unknown(),
  events: z.unknown(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const eSignAdhocCeremonyModelWithStoredFiles = eSignAdhocCeremonyModel.extend({
  storedFiles: z.array(eSignAdhocStoredFileModel).optional(),
});

export type ESignAdhocCeremonyModel = KeepTypeAlias<z.infer<typeof eSignAdhocCeremonyModel>>;

export type ESignAdhocCeremonyModelWithStoredFiles = KeepTypeAlias<
  z.infer<typeof eSignAdhocCeremonyModelWithStoredFiles>
>;
