import * as z from 'zod';
import { KeepTypeAlias } from '../utilities';
import { adhocTemplateConfig } from './eSignAdhoc';

export const eSignAdhocTemplateModel = z.object({
  id: z.string(),
  name: z.string(),
  document: z.instanceof(Buffer),
  config: adhocTemplateConfig,
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ESignAdhocTemplateModel = KeepTypeAlias<z.infer<typeof eSignAdhocTemplateModel>>;
