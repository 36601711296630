import { z } from 'zod';

export const CloverAmountSchema = z.number().nonnegative();
export type CloverAmount = z.infer<typeof CloverAmountSchema>;

export const CloverCreateChargeParamsSchema = z.object({
  requestIP: z.string(),
  appId: z.string(),
  source: z.string(),
  amount: CloverAmountSchema,
});
export type CloverCreateChargeParams = z.infer<typeof CloverCreateChargeParamsSchema>;

export type CloverEvent = {
  type: { error?: string; touched?: boolean };
};

export type CloverItem = {
  mounted: boolean;
  mountedIFrame: HTMLElement;
  mountNode: (node: HTMLInputElement) => void;
  addEventListener: (type: string, listener: (event: CloverEvent) => void) => void;
  removeEventListener: (type: string, listener: (event: CloverEvent) => void) => void;
};

export type Elements = {
  create: (type: string, style: Record<string, unknown>) => CloverItem;
};

export type CreateToken = {
  token: string;
  errors?: Record<string, unknown>;
};

export interface Clover {
  elements(): Elements;
  createToken(): CreateToken;
}

export interface CloverConstructor {
  new (apikey: string, options: Record<string, unknown>): Clover;
  (apikey: string, options: Record<string, unknown>): Clover;
  readonly prototype: Clover;
}
