import * as z from 'zod';
import { KeepTypeAlias } from '../utilities';

export const linesOfBusinessQuestionnairesSchema = z.object({
  id: z.string(),
  linesOfBusinessId: z.string(),
  questionnaireId: z.string(),
});

export const linesOfBusinessQuestionnairesArraySchema = z.array(linesOfBusinessQuestionnairesSchema);

export type LinesOfBusinessQuestionnaires = KeepTypeAlias<z.infer<typeof linesOfBusinessQuestionnairesSchema>>;
