import { z } from 'zod';

export const ESalesEmployerSchema = z.object({
  code: z.number(),
  codeAlpha: z.string(),
  name: z.string(),
  complementaryName: z.string(),
  addressLine1: z.string(),
  addressLine2: z.string(),
  zipcode: z.string(),
  phoneNumber: z.string(),
  masterPay: z.string(),
  customerType: z.string(),
  category: z.number(),
  numberEmployees: z.number(),
  suspensionCode: z.string(),
  langue: z.number(),
  contactName: z.string(),
  localPhoneNumber: z.string(),
  deductionList: z.number(),
  reerCollectif: z.string(),
  requiredAuthor: z.number(),
  requiredRegistration: z.number(),
  nomberRegistration: z.coerce.string(),
  typeRegistration: z.string(),
  newPaymentAccepted: z.string(),
  pgmRdr: z.string(),
  seqImp: z.string(),
  officeNumber: z.number(),
  typeOffice: z.number(),
  succ: z.number(),
  coordinator: z.number(),
  group: z.number(),
  organization: z.number(),
  typeOrganization: z.string(),
  paymentModeMFQ: z.string(),
  paymentModeCap: z.string(),
  numberAnnualPayroll: z.number(),
  concPaiePer: z.string(),
  unallocatedAmount: z.number(),
  separateCheque: z.string(),
  nbRubanARec: z.number(),
  nbRubanRecu: z.number(),
  numberInvoices: z.number(),
  noPgmMaj: z.number(),
  updateDate: z.string(),
  indMarche: z.string(),
  typeMarche: z.string(),
  indCheqPaie: z.string(),
  nbJrsCheqPaie: z.number(),
  oldName1: z.string(),
  oldName2: z.string(),
  email: z.string(),
  noRemise: z.number(),
  bqInst: z.number(),
  dateLastVisit: z.number(),
  indRdrDetailReer: z.string(),
  numberAnnualPeriods: z.number(),
  cityProvince: z.string(),
  employerSummary: z.string(),
  institutionIdentifier: z.string(),
});

export type ESalesEmployer = z.infer<typeof ESalesEmployerSchema>;
