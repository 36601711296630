import { z } from 'zod';

export const PartialColorRangesValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 'default'] as const;
export const FullColorRangesValues = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] as const;

export const partialColorGradientSchema = z.object({
  [10]: z.string(),
  [20]: z.string(),
  [30]: z.string(),
  [40]: z.string(),
  [50]: z.string(),
  [60]: z.string(),
  [70]: z.string(),
  [80]: z.string(),
  [90]: z.string(),
  default: z.string(),
});

export const fullColorGradientSchema = z.object({
  [0]: z.string(),
  [10]: z.string(),
  [20]: z.string(),
  [30]: z.string(),
  [40]: z.string(),
  [50]: z.string(),
  [60]: z.string(),
  [70]: z.string(),
  [80]: z.string(),
  [90]: z.string(),
  [100]: z.string(),
});

export const colorRangesSchema = z.object({
  grey: fullColorGradientSchema.optional(),
  primary: partialColorGradientSchema,
  secondary: partialColorGradientSchema,
  tertiary: partialColorGradientSchema,
});

export type PartialColorGradient = z.infer<typeof partialColorGradientSchema>;
export type FullColorGradient = z.infer<typeof fullColorGradientSchema>;
export type ColorRanges = z.infer<typeof colorRangesSchema>;
