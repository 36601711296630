import { DataLabel } from '@breathelife/meta-cruncher';

import { isoCountryCode } from '../applications/isoCountryCode';
import { ApplicationMode } from '../applications/mode';
import { IconName } from '../icons';
import { ParticipantRoles } from '../participant';
import { PdfDocumentType } from '../carrierQuestionnaire/documentType';
import { engineEffects } from '../carrierQuestionnaire/engineEffects';
import { fieldLayout } from '../carrierQuestionnaire/fieldLayout';
import { FieldTypes } from '../carrierQuestionnaire/fieldTypes';
import { InsuranceModule } from '../carrierQuestionnaire/insuranceModule';
import { OptionSize } from '../carrierQuestionnaire/optionSize';
import { PlatformType } from '../carrierQuestionnaire/platformType';
import { SubsectionVariant } from '../carrierQuestionnaire/subsectionVariant';
import { BlueprintConditionsValueWithMessage } from './conditionBlueprints';
import { blueprintConditionsValueWithMessage, blueprintConditionValue } from './conditionBlueprintParsers';
import { subsectionVariantBlueprint } from './subsectionVariantBlueprints';
import { t, IsTrue, Infer } from 'typegate';
import {
  AddressAutocompleteFieldBlueprint,
  AlwaysValid,
  BooleanFieldValidation,
  ButtonFieldBlueprint,
  DateFieldValidation,
  FieldBlueprint,
  FieldBlueprintBase,
  InformationFieldBlueprintVariant,
  InputFieldBlueprint,
  InputFieldValidation,
  MoneyFieldValidation,
  NumberFieldValidation,
  PhoneFieldValidation,
  QuestionnaireBlueprint,
  QuestionnaireBlueprintBase,
  QuestionnaireBlueprintCopyableOption,
  QuestionnaireBlueprintRenderOn,
  RadioFieldBlueprint,
  SelectOptionBlueprint,
  StringFieldValidation,
} from './questionnaireBlueprints';
import { Localizable } from '../localization';

const infoSupplementBlueprint = t
  .object(
    t.property('text', t.noCheck<Partial<Localizable>>()),
    t.optionalProperty('title', t.noCheck<Partial<Localizable>>()),
    t.optionalProperty('image', t.object(t.property('name', t.literal('cheque')))),
  )
  .setTypeName('InfoSupplementBlueprint');

const dynamicOptionsBlueprint = t
  .object(
    t.property('collection', t.string),
    t.property('select', t.array(t.string)),
    t.optionalProperty('visible', blueprintConditionValue),
  )
  .setTypeName('DynamicOptionsBlueprint');

const questionnaireBlueprintBase = t
  .object(
    t.property('id', t.string),
    t.optionalProperty('referenceLabel', t.string),
    t.property('partName', t.string),
    t.optionalProperty('text', t.noCheck<Partial<Localizable>>()),
    t.optionalProperty('title', t.noCheck<Partial<Localizable>>()),
    t.optionalProperty('visible', blueprintConditionValue),
    t.optionalProperty('dataLabel', t.enum(DataLabel)),
    t.optionalProperty('platforms', t.array(t.enum(PlatformType))),
    t.optionalProperty('renderOn', t.array(t.enum(QuestionnaireBlueprintRenderOn))),
    t.optionalProperty('hidden', t.boolean),
    t.optionalProperty('isCustom', t.boolean),
    t.optionalProperty('copyable', t.enum(QuestionnaireBlueprintCopyableOption)),
  )
  .setTypeName('QuestionnaireBlueprintBase');

export type CheckA1 = IsTrue<
  Infer<typeof questionnaireBlueprintBase> extends QuestionnaireBlueprintBase ? true : false
>;
export type CheckA2 = IsTrue<
  QuestionnaireBlueprintBase extends Infer<typeof questionnaireBlueprintBase> ? true : false
>;

const fieldBlueprintBase = t.intersection(
  questionnaireBlueprintBase,
  t.object(
    t.property('answerNodeId', t.string),
    t.optionalProperty(
      'valid',
      t.lazy<BlueprintConditionsValueWithMessage[]>(() => t.array(blueprintConditionsValueWithMessage)),
    ),
    t.optionalProperty('optional', t.boolean),
    t.optionalProperty('disabled', t.boolean),
    t.optionalProperty('infoSupplement', infoSupplementBlueprint),
    t.optionalProperty('placeholder', t.noCheck<Partial<Localizable>>()),
    t.optionalProperty('iconName', t.enum(IconName)),
    t.optionalProperty('triggerStepNavigation', t.boolean),
    t.optionalProperty('layout', fieldLayout),
    t.optionalProperty('displayInCardPreview', t.boolean),
    t.optionalProperty('dynamicOptions', dynamicOptionsBlueprint),
    t.optionalProperty(
      'internal',
      t.object(
        t.optionalProperty('relatesTo', t.string),
        t.optionalProperty('computedValue', t.record(t.string, t.unknown)),
      ),
    ),
    t.optionalProperty('effects', engineEffects),
    t.optionalProperty('applicationModes', t.array(t.enum(ApplicationMode))),
  ),
);

export type CheckB1 = IsTrue<Infer<typeof fieldBlueprintBase> extends FieldBlueprintBase ? true : false>;
export type CheckB2 = IsTrue<FieldBlueprintBase extends Infer<typeof fieldBlueprintBase> ? true : false>;

const inputFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.input)),
      t.property('validateAs', t.enum(InputFieldValidation)),
      t.optionalProperty('defaultValue', t.string),
    ),
  )
  .setTypeName('InputFieldBlueprint');

export type CheckD1 = IsTrue<Infer<typeof inputFieldBlueprint> extends InputFieldBlueprint ? true : false>;
export type CheckD2 = IsTrue<InputFieldBlueprint extends Infer<typeof inputFieldBlueprint> ? true : false>;

const numberFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.number)),
      t.property('validateAs', t.enum(NumberFieldValidation)),
      t.optionalProperty('numericalDataType', t.union(t.literal('float'), t.literal('integer'))),
      t.optionalProperty('defaultValue', t.number),
    ),
  )
  .setTypeName('NumberFieldBlueprint');

const moneyFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.money)),
      t.property('validateAs', t.enum(MoneyFieldValidation)),
      t.optionalProperty('defaultValue', t.number),
    ),
  )
  .setTypeName('MoneyFieldBlueprint');

const dateFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.date)),
      t.property('validateAs', t.enum(DateFieldValidation)),
    ),
  )
  .setTypeName('DateFieldBlueprint');

const checkboxFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.checkbox)),
      t.property('validateAs', t.enum(BooleanFieldValidation)),
      t.optionalProperty('defaultValue', t.boolean),
    ),
  )
  .setTypeName('CheckboxFieldBlueprint');

const textareaFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.textarea)),
      t.property('validateAs', t.enum(StringFieldValidation)),
      t.optionalProperty('defaultValue', t.string),
    ),
  )
  .setTypeName('TextareaFieldBlueprint');

const phoneFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.phone)),
      t.property('validateAs', t.enum(PhoneFieldValidation)),
    ),
  )
  .setTypeName('PhoneFieldBlueprint');

const informationFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.information)),
      t.property('validateAs', t.enum(StringFieldValidation)),
      t.property('variant', t.enum(InformationFieldBlueprintVariant)),
    ),
  )
  .setTypeName('InformationFieldBlueprint');

const buttonFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.button)),
      t.property('validateAs', t.enum(BooleanFieldValidation)),
      t.property('buttonText', t.noCheck<Partial<Localizable>>()),
    ),
  )
  .setTypeName('ButtonFieldBlueprint');

export type CheckE1 = IsTrue<Infer<typeof buttonFieldBlueprint> extends ButtonFieldBlueprint ? true : false>;
export type CheckE2 = IsTrue<ButtonFieldBlueprint extends Infer<typeof buttonFieldBlueprint> ? true : false>;

const currencyCardFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.currencyCard)),
      t.property('validateAs', t.enum(NumberFieldValidation)),
    ),
  )
  .setTypeName('CurrencyCardFieldBlueprint');

const addressAutocompleteFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.autocomplete)),
      t.property('validateAs', t.enum(StringFieldValidation)),
      t.property('countryCode', isoCountryCode),
      // When the `addressAutocompleteFields.streetAddress` is not set we still need to store the autocomplete data somewhere.
      t.optionalProperty('addressAutocompleteNodeId', t.string),
      // Store the partNames of the blueprints associated with this addressAutocomplete blueprint.
      t.property(
        'addressAutocompleteFields',
        t.object(
          t.optionalProperty('streetAddress', t.string),
          t.property('city', t.string),
          t.property('stateOrProvince', t.string),
          t.property('postalCodeOrZip', t.string),
        ),
      ),
    ),
  )
  .setTypeName('AddressAutocompleteFieldBlueprint');

export type CheckF1 = IsTrue<
  Infer<typeof addressAutocompleteFieldBlueprint> extends AddressAutocompleteFieldBlueprint ? true : false
>;
export type CheckF2 = IsTrue<
  AddressAutocompleteFieldBlueprint extends Infer<typeof addressAutocompleteFieldBlueprint> ? true : false
>;

const selectOptionBlueprint = t
  .object(
    t.property('partName', t.string),
    t.optionalProperty('hidden', t.boolean),
    t.optionalProperty('isCustom', t.boolean),
    t.property('text', t.noCheck<Partial<Localizable>>()),
    t.optionalProperty('visible', blueprintConditionValue),
    t.optionalProperty(
      'internal',
      t.object(
        t.optionalProperty('platformTypeFilter', t.array(t.enum(PlatformType))),
        t.optionalProperty('checked', t.boolean),
      ),
    ),
    t.optionalProperty('orderingIndex', t.number),
  )
  .setTypeName('SelectOptionBlueprint');

export type CheckG1 = IsTrue<Infer<typeof selectOptionBlueprint> extends SelectOptionBlueprint ? true : false>;
export type CheckG2 = IsTrue<SelectOptionBlueprint extends Infer<typeof selectOptionBlueprint> ? true : false>;

const radioFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.radio)),
      t.property('validateAs', t.union(t.enum(StringFieldValidation), t.enum(BooleanFieldValidation))),
      t.property('selectOptions', t.array(selectOptionBlueprint)),
      t.optionalProperty('optionSize', t.enum(OptionSize)),
      t.optionalProperty('defaultValue', t.union(t.string, t.boolean)),
    ),
  )
  .setTypeName('RadioFieldBlueprint');

export type CheckH1 = IsTrue<Infer<typeof radioFieldBlueprint> extends RadioFieldBlueprint ? true : false>;
export type CheckH2 = IsTrue<RadioFieldBlueprint extends Infer<typeof radioFieldBlueprint> ? true : false>;

const agreeFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.agree)),
      t.property('validateAs', t.enum(BooleanFieldValidation)),
      t.property('confirmedLabel', t.noCheck<Partial<Localizable>>()),
      t.property('modalHeader', t.noCheck<Partial<Localizable>>()),
      t.property('modalText', t.noCheck<Partial<Localizable>>()),
    ),
  )
  .setTypeName('AgreeFieldBlueprint');

const signatureFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.signature)),
      t.property('validateAs', t.literal(AlwaysValid.signature)),
      t.property('participantRole', t.enum(ParticipantRoles)),
    ),
  )
  .setTypeName('SignatureFieldBlueprint');

const sectionGroupKey = t.union(t.literal('insuredPeople'), t.literal('contract'));

const selectOptionsApplicationContextBlueprint = t.object(
  t.optionalProperty('tag', t.string),
  t.optionalProperty('labelKey', t.noCheck<Partial<Localizable>>()),
  t.optionalProperty('valuePath', t.string),
);

const selectOptionFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.dropdown)),
      t.property('selectOptions', t.array(selectOptionBlueprint)),
      t.optionalProperty('selectOptionsApplicationContext', selectOptionsApplicationContextBlueprint),
      t.property('validateAs', t.enum(StringFieldValidation)),
      t.optionalProperty('defaultValue', t.union(t.string, t.array(t.string))),
      t.optionalProperty('searchable', t.boolean),
    ),
  )
  .setTypeName('SelectOptionFieldBlueprint');

const checkBoxGroupFieldBlueprint = t
  .intersection(
    fieldBlueprintBase,
    t.object(
      t.property('fieldType', t.literal(FieldTypes.checkboxGroup)),
      t.property('selectOptions', t.array(selectOptionBlueprint)),
      t.property('validateAs', t.enum(StringFieldValidation)),
      t.optionalProperty('defaultValue', t.union(t.string, t.array(t.string))),
    ),
  )
  .setTypeName('CheckBoxGroupFieldBlueprint');

const fieldBlueprint = t
  .discriminatedUnion(
    'fieldType',
    inputFieldBlueprint,
    selectOptionFieldBlueprint,
    numberFieldBlueprint,
    moneyFieldBlueprint,
    dateFieldBlueprint,
    checkboxFieldBlueprint,
    checkBoxGroupFieldBlueprint,
    textareaFieldBlueprint,
    phoneFieldBlueprint,
    informationFieldBlueprint,
    buttonFieldBlueprint,
    currencyCardFieldBlueprint,
    addressAutocompleteFieldBlueprint,
    radioFieldBlueprint,
    agreeFieldBlueprint,
    signatureFieldBlueprint,
  )
  .setTypeName('FieldBlueprint');

export type CheckC1 = IsTrue<Infer<typeof fieldBlueprint> extends FieldBlueprint ? true : false>;
export type CheckC2 = IsTrue<FieldBlueprint extends Infer<typeof fieldBlueprint> ? true : false>;

const questionBlueprint = t.intersection(
  questionnaireBlueprintBase,
  t.object(
    t.property('fields', t.array(fieldBlueprint)),
    t.optionalProperty(
      'repeatable',
      t.object(
        t.property('repeatableAnswerNodeId', t.string),
        t.property('addButtonText', t.noCheck<Partial<Localizable>>()),
        t.property('removeButtonText', t.noCheck<Partial<Localizable>>()),
        t.property('minRepeatable', t.number),
        t.property('maxRepeatable', t.number),
      ),
    ),
    t.optionalProperty('internal', t.object(t.optionalProperty('showInPdfWithoutFields', t.boolean))),
    t.optionalProperty('displayAsCard', t.boolean),
    t.optionalProperty('effects', engineEffects),
  ),
);

const subsectionBlueprint = t.intersection(
  questionnaireBlueprintBase,
  t.object(
    t.property('questions', t.array(questionBlueprint)),
    t.optionalProperty('nextStepButtonText', t.noCheck<Partial<Localizable>>()),
    t.optionalProperty('variant', subsectionVariantBlueprint),
    t.optionalProperty('iconName', t.enum(IconName)),
    t.optionalProperty(
      'internal',
      t.object(
        t.optionalProperty('variant', t.enum(SubsectionVariant)),
        t.optionalProperty('variantOptions', t.object(t.optionalProperty('useApplicationMonthlyPremium', t.boolean))),
      ),
    ),
    t.optionalProperty('showInNavigation', t.boolean),
    t.optionalProperty('pageBreakSubSectionInPdf', t.boolean),
  ),
);

const sectionBlueprint = t.intersection(
  questionnaireBlueprintBase,
  t.object(
    t.property('sectionGroupKey', sectionGroupKey),
    t.property('subsections', t.array(subsectionBlueprint)),
    t.optionalProperty('pdfDocuments', t.array(t.enum(PdfDocumentType))),
    t.optionalProperty('modules', t.array(t.enum(InsuranceModule))),
    t.optionalProperty('iconName', t.enum(IconName)),
  ),
);

const sectionGroupBlueprint = t.intersection(
  questionnaireBlueprintBase,
  t.object(
    t.optionalProperty(
      'repeatable',
      t.object(
        t.property('repeatableAnswerNodeId', t.string),
        t.property('minRepeatable', t.number),
        t.property('maxRepeatable', t.number),
      ),
    ),
    t.optionalProperty('effects', engineEffects),
  ),
);

const lspHeavyQuestionnaireBlueprint = t.object(
  t.property('sectionBlueprints', t.array(sectionBlueprint)),
  t.property(
    'sectionGroupBlueprints',
    t.object(
      t.optionalProperty('insuredPeople', sectionGroupBlueprint),
      t.optionalProperty('contract', sectionGroupBlueprint),
    ),
  ),
);

export type FinalCheck1 = IsTrue<
  Infer<typeof lspHeavyQuestionnaireBlueprint> extends QuestionnaireBlueprint ? true : false
>;
export type FinalCheck2 = IsTrue<
  QuestionnaireBlueprint extends Infer<typeof lspHeavyQuestionnaireBlueprint> ? true : false
>;

export const questionnaireBlueprint = t.lazy<QuestionnaireBlueprint>(() => lspHeavyQuestionnaireBlueprint);
