import { v4 as uuid } from 'uuid';
import { Language } from '../localization';
import { Timezone } from '../date';
import { z } from 'zod';
import { KeepTypeAlias } from '../utilities';

export enum DateFormat {
  yearMonthDay = 'yearMonthDay',
  monthDayYear = 'monthDayYear',
  dayMonthYear = 'dayMonthYear',
  monthTextDayCommaYear = 'monthTextDayCommaYear',
}

export const signatureCluster = z.object({
  type: z.literal('SignatureCluster'),
  dateArea: z.union([
    z.null(),
    z.object({
      page: z.number(),
      x: z.number(),
      y: z.number(),
      width: z.number(),
      height: z.number(),
      fontSize: z.number(),
      format: z.nativeEnum(DateFormat),
    }),
  ]),
  referenceArea: z.object({
    page: z.number(),
    x: z.number(),
    y: z.number(),
    width: z.number(),
    height: z.number(),
    fontSize: z.number(),
    label: z.string(),
  }),
  signatureArea: z.object({
    page: z.number(),
    x: z.number(),
    y: z.number(),
    roleLabels: z.record(z.string(), z.string()),
  }),
});
export type SignatureCluster = KeepTypeAlias<z.infer<typeof signatureCluster>>;

export const adhocTemplateConfig = signatureCluster;

export type AdhocTemplateConfig = KeepTypeAlias<z.infer<typeof adhocTemplateConfig>>;

export const eSignAdhocTemplate = z.object({
  id: z.string(),
  name: z.string(),
  document: z.instanceof(Buffer),
  config: adhocTemplateConfig,
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ESignAdhocTemplate = KeepTypeAlias<z.infer<typeof eSignAdhocTemplate>>;

export enum ESignAdhocStatus {
  // Status handled through event received
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Completed = 'Completed',
  Error = 'Error',
  // Status set by a human action to cancel a signature request
  Cancelled = 'Cancelled',
}

export enum ESignAdhocProvider {
  OneSpan = 'OneSpan',
}

export const eSignAdhocTransaction = z.object({
  providerName: z.nativeEnum(ESignAdhocProvider),
  createdAt: z.date(),
  transactionId: z.string(),
});

export type ESignAdhocTransaction = KeepTypeAlias<z.infer<typeof eSignAdhocTransaction>>;

export enum ESignAdhocSignerStatus {
  Signed = 'Signed',
  NotSigned = 'NotSigned',
  Bounced = 'Bounced',
  Error = 'Error',
}

export const eSignAdhocSigner = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
  roles: z.array(z.string()),
  status: z.nativeEnum(ESignAdhocSignerStatus),
});

export type ESignAdhocSigner = KeepTypeAlias<z.infer<typeof eSignAdhocSigner>>;

export const eSignAdhocEmail = z.object({
  title: z.string(),
});

export type ESignAdhocEmail = KeepTypeAlias<z.infer<typeof eSignAdhocEmail>>;

export const eSignAdhocRequestDocumentToSign = z.object({
  templateId: z.string(),
  signers: z.array(eSignAdhocSigner),
});

export type ESignAdhocRequestDocumentToSign = KeepTypeAlias<z.infer<typeof eSignAdhocRequestDocumentToSign>>;

export const eSignAdhocOnCompleteDoNothing = z.object({
  type: z.literal('DoNothing'),
});
export const eSignAdhocOnCompleteTransmitToCarrier = z.object({
  type: z.literal('TransmitToCarrier'),
});

export const eSignAdhocOnComplete = z.union([eSignAdhocOnCompleteDoNothing, eSignAdhocOnCompleteTransmitToCarrier]);

export type ESignAdhocOnComplete = KeepTypeAlias<z.infer<typeof eSignAdhocOnComplete>>;

export type ESignAdhocRequestEvent = { createdBy: ESignAdhocProvider | 'AdminSupportTool'; raw: unknown };

export type ESignAdhocRequest = {
  id: string;
  context: string;
  applicationId: string;
  email: ESignAdhocEmail;
  timezone: Timezone;
  language: Language;
  status: ESignAdhocStatus;
  documentsToSign: ESignAdhocRequestDocumentToSign[];
  providerTransactions: ESignAdhocTransaction[];
  events: ESignAdhocRequestEvent[];
  onComplete: ESignAdhocOnComplete;
  createdAt: Date;
  updatedAt: Date;
  storedFiles?: Array<ESignAdhocStoredFile>;
};

export function addTransaction(request: ESignAdhocRequest, transaction: ESignAdhocTransaction): void {
  request.providerTransactions.push(transaction);
}

export function addEvent(request: ESignAdhocRequest, event: ESignAdhocRequestEvent): void {
  request.events.push(event);
}

export function setStatus(request: ESignAdhocRequest, status: ESignAdhocStatus): void {
  request.status = status;
}

export function isEventAboutLatestTransaction(request: ESignAdhocRequest, transactionId: string): boolean {
  const numberOfTransactions = request.providerTransactions.length;
  if (numberOfTransactions === 0) {
    return false;
  }

  const latestTransaction = request.providerTransactions[numberOfTransactions - 1];

  return latestTransaction.transactionId === transactionId;
}

export function setStatusForSignerOnAllDocuments(
  request: ESignAdhocRequest,
  signerId: string,
  status: ESignAdhocSignerStatus,
): void {
  for (const document of request.documentsToSign) {
    for (const signer of document.signers) {
      if (signer.id === signerId) {
        signer.status = status;
      }
    }
  }
}

export function setStatusForAllSignersOnAllDocuments(request: ESignAdhocRequest, status: ESignAdhocSignerStatus): void {
  for (const document of request.documentsToSign) {
    for (const signer of document.signers) {
      signer.status = status;
    }
  }
}

export function createESignAdhocRequest(
  args: {
    context: string;
    applicationId: string;
    timezone: Timezone;
    language: Language;
    email: ESignAdhocEmail;
    onComplete?: ESignAdhocOnComplete;
    storedFiles?: Array<ESignAdhocStoredFile>;
  },
  options?: { generateUUID?: () => string },
): ESignAdhocRequest {
  return {
    id: options?.generateUUID?.() || uuid(),
    context: args.context,
    applicationId: args.applicationId,
    timezone: args.timezone,
    language: args.language,
    email: args.email,
    status: ESignAdhocStatus.NotStarted,
    documentsToSign: [],
    providerTransactions: [],
    events: [],
    onComplete: args.onComplete || { type: 'DoNothing' },
    createdAt: new Date(),
    updatedAt: new Date(),
    storedFiles: args.storedFiles,
  };
}

export const eSignAdhocStoredFile = z.object({
  id: z.string(),
  requestId: z.string(),
  storageKey: z.string(),
  storageBucket: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ESignAdhocStoredFile = KeepTypeAlias<z.infer<typeof eSignAdhocStoredFile>>;

// types returned by the API

export const apiESignAdHocBaseRequestSchema = z.object({
  id: z.string().uuid(),
  context: z.string(),
  applicationId: z.string().uuid(),
  refNo: z.string(),
  status: z.nativeEnum(ESignAdhocStatus),
  createdAt: z.string().datetime(),
});

export type ApiESignAdHocBaseRequest = KeepTypeAlias<z.infer<typeof apiESignAdHocBaseRequestSchema>>;

export const apiESignAdhocSignerSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string(),
  roles: z.array(z.string()),
  status: z.nativeEnum(ESignAdhocSignerStatus),
});

export type ApiESignAdhocSigner = KeepTypeAlias<z.infer<typeof apiESignAdhocSignerSchema>>;

export const apiESignAdhocRequestDocumentToSignSchema = z.object({
  templateId: z.string(),
  templateName: z.string(),
  signers: z.array(apiESignAdhocSignerSchema),
});

export type ApiESignAdhocRequestDocumentToSign = KeepTypeAlias<
  z.infer<typeof apiESignAdhocRequestDocumentToSignSchema>
>;

export const apiESignAdhocTransactionSchema = z.object({
  providerName: z.nativeEnum(ESignAdhocProvider),
  createdAt: z.string().datetime(),
  transactionId: z.string(),
});

export type ApiESignAdhocTransaction = KeepTypeAlias<z.infer<typeof apiESignAdhocTransactionSchema>>;

export const apiESignAdhocRequestEventSchema = z.object({
  createdBy: z.union([z.nativeEnum(ESignAdhocProvider), z.literal('AdminSupportTool')]),
  raw: z.unknown(),
});

export type ApiESignAdhocRequestEvent = KeepTypeAlias<z.infer<typeof apiESignAdhocRequestEventSchema>>;

export const apiESignAdHocFullRequestSchema = apiESignAdHocBaseRequestSchema.and(
  z.object({
    documentsToSign: z.array(apiESignAdhocRequestDocumentToSignSchema),
    providerTransactions: z.array(apiESignAdhocTransactionSchema),
    events: z.array(apiESignAdhocRequestEventSchema),
  }),
);

export type ApiESignAdHocFullRequest = KeepTypeAlias<z.infer<typeof apiESignAdHocFullRequestSchema>>;
